import React from "react";

export const AccessDenied = () => {
  return (
    <div className="flex justify-center w-[100%] ">
      <i class="fa-solid fa-lock text-[50px]"></i>
      <p className="text-24px font-semibold">Access Denied</p>
    </div>
  );
};
