import React, { useEffect, useRef, useState } from "react";
import { Button, Drawer, Radio, Space } from "antd";
import { formatDistanceToNow } from "date-fns";
import { jwtDecode } from "jwt-decode";
import axiosClient from "../../axios-client";

import { Mentions } from "antd";

const SelectedComment = ({
  commentPopup,
  setCommentPopup,
  selectedText,
  handleComments,
  setHandleComments,
  handleParentComment,
  allSelectComments,
  handleSelectedCommentDelete,
  handleSelectedCommentResolve,
  // handleSelectedCommentEdit,
  selectCommentLoading,
  userDetail,
  getSelectComments,
  activeTeamMembers,
  setSelectedOption,
  selectedOption,
  selectCommentValue,
  setSelectCommentValue,
  selectEditCommentValue,
  setSelectEditCommentValue,
  handleChildComment,
  selectChildCommentLoading,
}) => {
  const addIconRef = useRef(null);
  const addPopup = useRef(null);
  const [open, setOpen] = useState(commentPopup);
  const [placement, setPlacement] = useState("right");
  const [openPopup, setOpenPopup] = useState(null);
  const [openReplyBox, setOpenReplyBox] = useState(null);
  const [topState, setTopState] = useState(null);

  const [editOpen, setEditOpen] = useState(null);

  useEffect(() => {
    const closeOnOutsideClick = (e) => {
      if (
        openPopup &&
        addPopup.current &&
        !addPopup.current.contains(e.target)
      ) {
        setOpenPopup(null);
      }
    };

    window.addEventListener("click", closeOnOutsideClick);

    return () => {
      window.removeEventListener("click", closeOnOutsideClick);
    };
  }, [openPopup]);

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const onClose = () => {
    setOpen(false);
    setCommentPopup(false);
  };

  const decoded = jwtDecode(localStorage.getItem("ACCESS_TOKEN"));

  const handleOpenPopup = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const element = e.target;

    if (element) {
      const top = element.getBoundingClientRect().top;

      setTopState(top - 120);

      setOpenPopup(e.target.id);
    }
  };
  const [textareaValues, setTextareaValues] = useState({});

  const [editValue, setEditValue] = useState(null);
  // Function to update textarea value based on comment UUID
  const updateTextareaValue = (uuid, value) => {
    setTextareaValues((prevState) => ({
      ...prevState,
      [uuid]: value,
    }));
    setHandleComments(value);
  };

  // Function to get textarea value based on comment UUID
  const getTextareaValue = (uuid) => {
    return textareaValues[uuid] || ""; // Return empty string if value not found
  };

  //edit

  const handleSelectedCommentEdit = (uuid) => {
    let payLoad = {
      comments: editValue,
      comment_uuid: uuid,
    };

    axiosClient
      .put(`/api/comments/editLineComment`, payLoad)
      .then((res) => {
        getSelectComments();
        setEditOpen(null);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelect = async (e) => {
    const clickedElementId = e.target.id;
    localStorage.setItem("id", clickedElementId);

    document.querySelectorAll("span").forEach((element) => {
      element.classList.remove("selectedComment");
    });

    const targetSpan = document.getElementById(localStorage.getItem("id"));
    if (targetSpan) {
      targetSpan.classList.add("selectedComment");
    }
    // setCommentPopup(true)
  };

  const handleChange = (newValue) => {
    setSelectCommentValue(newValue);
    setHandleComments(newValue);
  };

  const handleOptionSelected = (option) => {
    setSelectedOption([...selectedOption, option]);

    createSpan(option);
  };

  const createSpan = (option) => {
    setSelectCommentValue(selectCommentValue + option.value);
  };

  //edit

  const handleEditChange = (newValue) => {
    setSelectEditCommentValue(newValue);
  };

  const handleEditOptionSelected = (option) => {
    setSelectedOption([...selectedOption, option]);

    createEditSpan(option);
  };

  const createEditSpan = (option) => {
    setSelectEditCommentValue(selectEditCommentValue + option.value);
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          maxWidth: "280px",
          width: "100%",
        }}
      >
        <div style={{ flex: 1 }}>
          <div
            className={`${
              typeof commentPopup == "string" ? "max-h-[412px]" : "max-h-[100%]"
            } overflow-auto`}
          >
            <div className=" w-[100%] shadow-sm flex justify-between p-3">
              <p>In this page comments</p>
              <i
                className="fa-solid fa-x cursor-pointer"
                title="Close"
                onClick={() => setCommentPopup(false)}
              ></i>
            </div>
            {allSelectComments?.length > 0 ? (
              <div className="overflow-hidden overflow-y-scroll overflow-x-hidden h-[402px] no-scrollbar">
                {allSelectComments.map((parentComment) => (
                  <div key={parentComment.uuid}>
                    <div className="mt-2 pb-3">
                      <div className="w-[90%] m-auto relative">
                        <div className="flex space-x-2 items-center">
                          <div id={parentComment.uuid}>
                            {parentComment?.user?.avatar ? (
                              <img
                                src={parentComment?.user?.avatar}
                                className="w-8 h-8 rounded-full "
                                alt=""
                              />
                            ) : (
                              <i className="fa-regular text-slate-600 fa-circle-user text-2xl cursor-pointer pr-1 mt-1"></i>
                            )}
                          </div>
                          <div className="flex items-center justify-between w-[100%]">
                            <div className="flex space-x-2 items-center">
                              <p className="text-[14px]">
                                {decoded.id === parentComment?.user.uuid
                                  ? "You"
                                  : parentComment?.user.username}
                              </p>
                              <p className="text-[12px] text-gray-400">
                                {formatDistanceToNow(
                                  new Date(parentComment?.updatedAt),
                                  { addSuffix: true }
                                )}
                              </p>
                            </div>
                            {decoded.id === parentComment?.user.uuid && (
                              <div className="relative">
                                <i
                                  className="fa-solid fa-ellipsis-vertical cursor-pointer pr-5 text-[16px] text-gray-500"
                                  id={parentComment.uuid}
                                  ref={addIconRef}
                                  onClick={(e) => handleOpenPopup(e)}
                                ></i>
                              </div>
                            )}

                            {openPopup === parentComment.uuid && (
                              <>
                                <div className="bg-[#a3a2e9] opacity-[0] w-screen  absolute top-0 left-0 z-10"></div>
                                <div
                                  className="box-border bg-white w-40 border-[1px] border-slate-300 rounded-lg shadow-lg absolute left-10 z-20"
                                  ref={addPopup}
                                  style={{ top: topState }}
                                >
                                  <div className="w-[90%] m-auto py-1">
                                    <div
                                      className="m-auto space-y-3 py-1"
                                      onClick={() =>
                                        handleSelectedCommentResolve(
                                          parentComment.uuid
                                        )
                                      }
                                    >
                                      <p className="text-[16px] cursor-pointer text-textPrimary hover:bg-primary hover:text-white hover:rounded">
                                        <i className="fa-regular fa-circle-check p-2"></i>
                                        Resolve
                                      </p>
                                    </div>

                                    <div
                                      className="m-auto space-y-3 py-1"
                                      onClick={() => {
                                        setOpenPopup(null);
                                        setEditOpen(parentComment?.uuid);
                                        setEditValue(parentComment?.comment);
                                      }}
                                    >
                                      <p className="text-[16px] cursor-pointer text-textPrimary hover:bg-primary hover:text-white hover:rounded">
                                        <i className="fa-regular fa-pen-to-square p-2"></i>
                                        Edit
                                      </p>
                                    </div>
                                    <div
                                      className="m-auto space-y-3 py-1"
                                      onClick={() =>
                                        handleSelectedCommentDelete(
                                          parentComment.uuid
                                        )
                                      }
                                    >
                                      <p className="text-[16px] cursor-pointer text-textPrimary hover:bg-primary hover:text-white hover:rounded">
                                        <i className="fa-solid fa-trash p-2"></i>
                                        Delete
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="pt-4">
                          <a
                            href={"#" + parentComment?.element_id}
                            className={`text-[#495057] ${
                              window.location.hash ===
                              "#" + parentComment?.element_id
                                ? "font-medium text-primary"
                                : ""
                            } text-sm`}
                            onClick={(e) => handleSelect(e)}
                            id={parentComment?.element_id}
                          >
                            {parentComment?.content}
                          </a>
                          {editOpen == parentComment?.uuid ? (
                            <div>
                              <input
                                type="text"
                                value={editValue}
                                onChange={(e) => setEditValue(e.target.value)}
                                className="w-full outline-none border-slate-400  px-3 py-1 text-md rounded-lg "
                              />
                              <div>
                                <div className="flex justify-start space-x-2 pt-[10px]">
                                  <button
                                    type="button"
                                    className="text-black border-gray-300 border-[1px] hover:bg-primary hover:text-white focus:ring-4 font-medium rounded-lg text-sm px-2 py-1 "
                                    onClick={() => setEditOpen("")}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="text-white bg-primary hover:bg-blue-800 focus:ring-4 font-medium rounded-lg text-sm px-2 py-1 flex items-center"
                                    onClick={() =>
                                      handleSelectedCommentEdit(
                                        parentComment?.uuid
                                      )
                                    }
                                  >
                                    <i class="fa-regular fa-pen-to-square pr-[8px]"></i>
                                    Edit
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <p className="pl-3 text-[14px] text-gray-800 font-medium">
                              {parentComment?.comment}
                            </p>
                          )}
                        </div>
                        {parentComment.ChildComments &&
                          parentComment.ChildComments.length > 0 && (
                            <div className="ml-8">
                              {parentComment.ChildComments.map(
                                (childComment) => (
                                  <div
                                    key={childComment.uuid}
                                    className="mt-2 pb-3"
                                  >
                                    <div className="w-[90%] m-auto">
                                      <div className="flex space-x-2 items-center">
                                        <div id={childComment.uuid}>
                                          {childComment?.user?.avatar ? (
                                            <img
                                              src={childComment?.user?.avatar}
                                              className="w-8 h-8 rounded-full "
                                              alt=""
                                            />
                                          ) : (
                                            <i className="fa-regular text-slate-600 fa-circle-user text-2xl cursor-pointer pr-1 mt-1"></i>
                                          )}
                                        </div>

                                        <div className="flex items-center justify-between w-[100%]">
                                          <div className="flex space-x-2 items-center">
                                            <p className="text-[14px]">
                                              {decoded.id ===
                                              childComment?.user_uuid
                                                ? "You"
                                                : childComment?.user.username}
                                            </p>
                                            <p className="text-[12px] text-gray-400">
                                              {formatDistanceToNow(
                                                new Date(
                                                  childComment?.updatedAt
                                                ),
                                                { addSuffix: true }
                                              )}
                                            </p>
                                          </div>
                                          {decoded.id ===
                                            childComment?.user_uuid && (
                                            <div className="relative">
                                              <i
                                                className="fa-solid fa-ellipsis-vertical cursor-pointer pr-1.5 text-[16px] text-gray-500"
                                                id={childComment.uuid}
                                                ref={addIconRef}
                                                onClick={(e) =>
                                                  handleOpenPopup(e)
                                                }
                                              ></i>
                                            </div>
                                          )}

                                          {openPopup === childComment.uuid && (
                                            <>
                                              <div className="bg-[#a3a2e9] opacity-[0] w-screen h-screen absolute top-0 left-0 z-10"></div>
                                              <div
                                                className="box-border bg-white w-40 border-[1px] border-slate-300 rounded-lg shadow-lg absolute left-11 z-20"
                                                ref={addPopup}
                                                style={{ top: topState }}
                                              >
                                                <div className="w-[90%] m-auto py-1">
                                                  <div
                                                    className="m-auto space-y-3 py-1"
                                                    onClick={() =>
                                                      handleSelectedCommentResolve(
                                                        childComment.uuid
                                                      )
                                                    }
                                                  >
                                                    <p className="text-[16px] cursor-pointer text-textPrimary hover:bg-primary hover:text-white hover:rounded">
                                                      <i className="fa-regular fa-circle-check p-2"></i>
                                                      Resolve
                                                    </p>
                                                  </div>
                                                  <div
                                                    className="m-auto space-y-3 py-1"
                                                    onClick={() => {
                                                      setOpenPopup(null);

                                                      setEditOpen(
                                                        childComment?.uuid
                                                      );
                                                      setEditValue(
                                                        childComment?.comment
                                                      );
                                                    }}
                                                  >
                                                    <p className="text-[16px] cursor-pointer text-textPrimary hover:bg-primary hover:text-white hover:rounded">
                                                      <i className="fa-regular fa-pen-to-square p-2"></i>
                                                      Edit
                                                    </p>
                                                  </div>
                                                  <div
                                                    className="m-auto space-y-3 py-1"
                                                    onClick={() =>
                                                      handleSelectedCommentDelete(
                                                        childComment.uuid
                                                      )
                                                    }
                                                  >
                                                    <p className="text-[16px] cursor-pointer text-textPrimary hover:bg-primary hover:text-white hover:rounded">
                                                      <i className="fa-solid fa-trash p-2"></i>
                                                      Delete
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className="pt-2">
                                        {editOpen == childComment?.uuid ? (
                                          <div>
                                            <input
                                              type="text"
                                              value={editValue}
                                              onChange={(e) =>
                                                setEditValue(e.target.value)
                                              }
                                              className="w-full outline-none border-slate-400  px-3 py-1 text-lg"
                                            />
                                            <div>
                                              <div className="flex justify-start space-x-2">
                                                <button
                                                  type="button"
                                                  className="text-black border-gray-300 border-[1px] hover:bg-primary hover:text-white focus:ring-4 font-medium rounded-lg text-sm px-3 py-1.5"
                                                  onClick={() =>
                                                    setEditOpen("")
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                                <button
                                                  type="button"
                                                  className="text-white bg-primary hover:bg-blue-800 focus:ring-4 font-medium rounded-lg text-sm px-3 py-1.5"
                                                  onClick={() =>
                                                    handleSelectedCommentEdit(
                                                      childComment?.uuid
                                                    )
                                                  }
                                                >
                                                  <i class="fa-regular fa-pen-to-square pr-[8px]"></i>
                                                  Edit
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <p className="pl-3 text-[16px] text-gray-800 font-medium">
                                            {childComment?.comment}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        <div className="flex space-x-3 items-center pt-4">
                          {userDetail?.avatar ? (
                            <img
                              src={userDetail?.avatar}
                              className="w-8 h-8 rounded-full "
                              alt=""
                            />
                          ) : (
                            <i className="fa-regular text-slate-600 fa-circle-user text-2xl cursor-pointer pr-1 mt-1"></i>
                          )}
                          <div className="w-[100%] pt-[5px]  ">
                            <Mentions
                              style={{
                                width: "100%",
                                padding: "20px ,0px",
                              }}
                              placement="top"
                              options={activeTeamMembers.map((user) => ({
                                value: user.username,
                                label: user.username,
                                id: user.uuid,
                              }))}
                              value={selectEditCommentValue}
                              onChange={handleEditChange}
                              onSelect={handleEditOptionSelected}
                              onClick={() =>
                                setOpenReplyBox(parentComment?.uuid)
                              }
                            />
                            {/* <textarea
                              type="text"
                              rows={1}
                              className={`w-[100%] outline-none ${
                                openReplyBox == parentComment?.uuid
                                  ? "pb-[20px]"
                                  : "pb-[0px]"
                              } bg-transparent `}
                              value={
                                handleComments == null
                                  ? ""
                                  : getTextareaValue(parentComment?.uuid)
                              }
                              placeholder="Type a new comment"
                              onChange={(e) =>
                                updateTextareaValue(
                                  parentComment?.uuid,
                                  e.target.value
                                )
                              }
                              onClick={() =>
                                setOpenReplyBox(parentComment?.uuid)
                              }
                            /> */}
                          </div>
                        </div>

                        {openReplyBox == parentComment?.uuid && (
                          <div className="flex justify-end pt-4 space-x-2">
                            <button
                              type="button"
                              className="text-black border-gray-300 border-[1px] hover:bg-primary hover:text-white focus:ring-4 font-medium rounded-lg text-sm px-3 py-1.5 mb-4"
                              onClick={() => setOpenReplyBox(!openReplyBox)}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className={`text-white bg-blue-700 flex justify-between items-center hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 mb-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 `}
                              id={parentComment?.uuid}
                              onClick={() =>
                                handleChildComment(
                                  parentComment?.uuid,
                                  parentComment?.content
                                )
                              }
                              // disabled={
                              //   selectCommentLoading?.stateCheck == true &&
                              //   selectCommentLoading.value == null
                              //     ? true
                              //     : false
                              // }
                            >
                              {selectChildCommentLoading ? (
                                // selectCommentLoading.value == null ? (
                                <div role="status" className="">
                                  <svg
                                    aria-hidden="true"
                                    className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <i className="fa-regular fa-paper-plane pr-[8px]"></i>
                              )}
                              <span>Comment</span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <hr />
                  </div>
                ))}
              </div>
            ) : (
              <div className="mt-28">
                <div>
                  <p className="text-center">
                    <i className="fa-regular fa-message text-xl text-gray-800 cursor-pointer "></i>
                  </p>
                  <p className="font-medium text-2xl pt-2 text-center">
                    No Comments Yet
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        {commentPopup && (
          <div
            className="bg-[#ffff] fixed bottom-0 w-[20%]"
            style={{ boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 12px 0px" }}
          >
            {selectedText && (
              <>
                <label
                  htmlFor=""
                  className="pl-[10px] font-medium inline-block pt-[10px]"
                >
                  Selected text
                </label>
                <p className="pl-[10px] text-gray-500">{selectedText}</p>
              </>
            )}

            <div className="flex space-x-3 m-auto pt-[20px] pb-[15px] pr-[10px] pl-[10px]">
              {userDetail?.avatar ? (
                <img
                  src={userDetail?.avatar}
                  className="w-8 h-8 rounded-full "
                  alt=""
                />
              ) : (
                <i className="fa-regular text-slate-600 fa-circle-user text-2xl cursor-pointer pr-1 mt-1"></i>
              )}

              {/* <div className="w-[100%] pt-[5px] pl-[10px] border-slate-400 border-[1px]  rounded-lg bg-transparent "> */}
              {/* <textarea
                  type="text"
                  className=" w-[100%] outline-none"
                  placeholder="Type a new comment"
                  value={handleComments == null ? "" : handleComments}
                  onChange={(e) => setHandleComments(e.target.value)}
                /> */}

              <Mentions
                style={{
                  width: "100%",
                  padding: "20px ,0px",
                }}
                placement="top"
                options={activeTeamMembers.map((user) => ({
                  value: user.username,
                  label: user.username,
                  id: user.uuid,
                }))}
                value={selectCommentValue}
                onChange={handleChange}
                onSelect={handleOptionSelected}
              />
            </div>
            <div className="flex m-auto justify-end pr-[10px]">
              <button
                type="button"
                className={`text-white bg-blue-700 flex justify-between items-center hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 mb-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ${
                  selectCommentLoading?.stateCheck == true &&
                  selectCommentLoading.value !== null
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                onClick={handleParentComment}
                disabled={
                  selectCommentLoading?.stateCheck == true &&
                  selectCommentLoading.value !== null
                    ? true
                    : false
                }
              >
                {selectCommentLoading ? (
                  <div role="status" className="">
                    <svg
                      aria-hidden="true"
                      className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <i className="fa-regular fa-paper-plane pr-[6px]"></i>
                )}
                <span>Comment</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default SelectedComment;
