import React, { useState } from "react";
import { Button, Form, Input, Radio } from "antd";
const ModalComponent = ({
  set,
  setForgotPasswordPopup,
  changeState,
  emailError,
  onFinish,
  setEmailError,
  popUpLoading,
  passwordError,
  setPasswordError,
}) => {
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="pt-10 ">
      <p className="text-xl font-medium pl-8 pb-2">Please {set} to here!</p>
      <div className="">
        <Form
          name="basic"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {set == "Signup" && (
            <Form.Item
              className="py-2"
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
                {
                  pattern: /^[^\s].*$/, // This regular expression ensures that the username does not start with a space
                  message: "Username cannot start with a space!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            style={{ marginBottom: emailError ? "0px" : "" }}
            className="py-2"
            label="YourEmail"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                type: "email",
                message: "Please enter a valid email address!",
              },

              {
                validator: (_, value) => {
                  if (/\s/.test(value)) {
                    return Promise.reject(
                      "Whitespace is not allowed in the email!"
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input onChange={() => setEmailError(null)} />
          </Form.Item>
          {emailError ? (
            <span
              style={{ color: "#ff4d4f" }}
              className="flex justify-center mb-6"
            >
              {emailError}
            </span>
          ) : (
            ""
          )}
          {set !== "Forgot Password" && (
            <Form.Item
              style={{ marginBottom: passwordError ? "0px" : "" }}
              className=""
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  validator: (_, value) => {
                    if (/\s/.test(value)) {
                      return Promise.reject(
                        "Whitespace is not allowed in the password!"
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
                {
                  min: 8,
                  message: "Password must be at least 8 characters long!",
                },
                {
                  validator: (_, value) => {
                    if (!/(?=.*[A-Z])/.test(value)) {
                      return Promise.reject(
                        "Password must contain at least one uppercase letter!"
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
                {
                  validator: (_, value) => {
                    if (!/(?=.*[!@#$%^&*])/.test(value)) {
                      return Promise.reject(
                        "Password must contain at least one special character!"
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input.Password onChange={() => setPasswordError(null)} />
            </Form.Item>
          )}
          {console.log(passwordError,"okk")}
          {passwordError ? (
            <span
              style={{ color: "#ff4d4f" }}
              className="flex justify-center mb-6"
            >
              {passwordError}
            </span>
          ) : (
            ""
          )}{" "}
          {set == "Signin" && (
            <div className="flex justify-end pr-10">
              <button
                className="cursor-pointer text-primary"
                onClick={() => {
                  changeState("forgotPassword");
                  setForgotPasswordPopup(true);
                }}
              >
                Forgot password
              </button>
            </div>
          )}
          <Form.Item
            wrapperCol={{
              offset: 4,
              span: 16,
            }}
          >
            <div className="pt-4">
              <Button
                type="primary"
                htmlType="submit"
                className="bg-primary h-10 text-center   "
                style={{ width: "100%" }}
              >
                {popUpLoading ? (
                  <>
                    <div role="status" className="">
                      <svg
                        aria-hidden="true"
                        className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </>
                ) : (
                  <>{set}</>
                )}
              </Button>
            </div>

            <>
              {set == "Signup" && (
                <div className="flex justify-end pr-16 pt-2">
                  Alreay have an account?{" "}
                  <span
                    className="text-primary font-medium cursor-pointer pl-1"
                    onClick={() => changeState("signin")}
                  >
                    Signin
                  </span>
                </div>
              )}
              {set == "Signin" && (
                <div className="flex justify-end pr-16 pt-2">
                  Alreay have an account?{" "}
                  <span
                    className="text-primary font-medium cursor-pointer pl-1"
                    onClick={() => changeState("signup")}
                  >
                    Signup
                  </span>
                </div>
              )}
            </>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default ModalComponent;
