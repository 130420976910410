import React, { useState } from "react";
import { Button, Drawer, Radio, Space } from "antd";
import { formatDistanceToNow } from "date-fns";
import { jwtDecode } from "jwt-decode";

const History = ({ historyPopup, setHistoryPopup, revisiedHistory }) => {
  const [open, setOpen] = useState(historyPopup);
  const [placement, setPlacement] = useState("right");
  const [showAll, setShowAll] = useState(false); // State to control whether to show all items or just the first 10
  const [visibleItemCount, setVisibleItemCount] = useState(10); // State to control visible item count

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const onClose = () => {
    setOpen(false);
    setHistoryPopup(false);
  };

  const decoded = jwtDecode(localStorage.getItem("ACCESS_TOKEN"));

  const renderedItems = showAll ? revisiedHistory : revisiedHistory.slice(0, visibleItemCount);


  const handleShowMore = () => {
    setVisibleItemCount((prevCount) => prevCount + 10);
  };

  return (
    <>
      <Drawer
        title="Revision History"
        placement={placement}
        width={400}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} className="border-none">
              <i className="fa-solid fa-xmark text-xl"></i>
            </Button>
          </Space>
        }
      >
        {renderedItems.length > 0 ? (
          <>
            {renderedItems.map((item, index) => (
              <div key={index} className="flex items-center pb-5">
                {item?.user?.avatar ? (
                  <img
                    src={item?.user?.avatar}
                    className="w-8 h-8 rounded-full mr-2"
                    alt=""
                  />
                ) : (
                  <i className="fa-regular text-slate-600 fa-circle-user text-xl cursor-pointer pr-3 mt-1"></i>
                )}
                <p className=" font-medium">
                  <span className="font-semibold ">
                    {decoded.id === item?.user_uuid ? "You" : item?.user.username}
                  </span>
                  <span className="pl-1">{item.action}</span>
                  {" . "}
                  <span className="text-gray-500 font-medium">
                    {formatDistanceToNow(new Date(item.updatedAt), {
                      addSuffix: true,
                    })}
                  </span>
                </p>
              </div>
            ))}
            {!showAll && (
              <div className="text-center">
                {visibleItemCount < revisiedHistory.length && (
                  <Button onClick={handleShowMore}>Show More</Button>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="mt-28">
            <div>
              <p className="text-center">
                <i className="fa-solid fa-clock-rotate-left text-xl text-gray-800 cursor-pointer "></i>
              </p>
              <p className="font-medium text-2xl pt-2 text-center">
                No details found
              </p>
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default History;
