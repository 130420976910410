import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import List from "@editorjs/list";
import Table from "@editorjs/table";
import Header from "@editorjs/header";
import InlineCode from "@editorjs/inline-code";
import Underline from "@editorjs/underline";
import Marker from "@editorjs/marker";
import Checklist from "@editorjs/checklist";
import Quote from "@editorjs/quote";
import Embed from "@editorjs/embed";
import ImageTool from "@editorjs/image";
import BreakLine from "editorjs-break-line";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../../axios-client";
import { PageTree } from "../commonComponents/PageTree";
import { Search } from "./Search";
import AttachesTool from "@editorjs/attaches";
import HashLoader from "react-spinners/HashLoader";
import { formatDistanceToNow, isValid } from "date-fns";
import "animate.css/animate.min.css";
import { ToastContainer, toast } from "react-toastify";
import { darken, lighten, getContrast, getLuminance } from "polished";
import ModalComponent from "../commonComponents/ModalComponent";
import { Modal, Tooltip } from "antd";
import html2pdf from "html2pdf.js";
import { Comments } from "../commonComponents/Comments";
import { jwtDecode } from "jwt-decode";
import axiosPublicClient from "../../script-axios-client";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AccessDenied } from "../commonComponents/AccessDenied";
import ProfilePagePopup from "../commonComponents/ProfilePagePopup";

export const UrlPage = () => {
  const location = useLocation();

  const profileRef = useRef(null);
  const profileIconRef = useRef(null);
  const navigate = useNavigate();
  const detailRef = useRef(null);
  const iconRef = useRef(null);

  const [profileState, setProfileState] = useState(false);

  const [script, setScript] = useState(null);
  const [page, setPages] = useState([]);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState(false);
  const [onThisPage, setOnThisPage] = useState([]);
  const [pageId, setpageId] = useState([]);
  //style State
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [customizeColor, setCustomizeColor] = useState(null);
  const [colorcheck, setColorCheck] = useState(false);

  const [serachPopup, setsearchPopup] = useState(false);
  const [searchPageData, setSearchPageData] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);

  const [parentOpen, setParentOpen] = useState(null);
  const [url, setUrl] = useState(null);
  const [loadPage, setLoadPage] = useState({});
  const [textBackground, setTextBackground] = useState(null);

  const [signinPopup, setSigninPopup] = useState(false);
  const [signupPopup, setSignupPopup] = useState(false);
  const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);

  const [commentValue, setCommentValue] = useState(null);

  const [commentLoading, setCommentLoading] = useState(false);
  const [commentAddLoading, setCommentAddLoading] = useState(false);

  const [openUrlSideNave, setOpenUrlSideNave] = useState("hidden");
  // comment CHeck

  const [replyText, setReplyText] = useState("");
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [showChildren, setShowChildren] = useState(true);
  const [comments, setComments] = useState(null);

  const [scriptUserInfo, setScriptUserInfo] = useState(null);

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const [popUpLoading, setPopUpLoading] = useState(null);
  const [profilePopup, setProfilePopup] = useState(false);

  //access State
  const [accessPage, setAccessPage] = useState(0);

  //profile
  const [username, setUsername] = useState("");

  //profile state
  const handleProfile = () => {
    setProfileState((prevState) => !prevState);
  };

  const searchInpRef = useRef();
  const createdAt = script?.createdAt;
  let formattedTime = "Invalid date";

  if (createdAt && isValid(new Date(createdAt))) {
    formattedTime = formatDistanceToNow(new Date(createdAt), {
      addSuffix: true,
    });
  }

  const updatedAt = script?.updatedAt;
  let formattedTimes = "Invalid date";

  if (createdAt && isValid(new Date(updatedAt))) {
    formattedTimes = formatDistanceToNow(new Date(updatedAt), {
      addSuffix: true,
    });
  }

  //toast message
  let duration = 2000;

  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
    });
  };

  //error toast message

  const showToastErrorMessage = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
      closeButton: () => (
        <div style={{ margin: "auto", fontSize: "22px" }}>
          <button className="custom-close-button">×</button>
        </div>
      ),
      style: {
        borderRadius: "8px",
        padding: "10px",
      },
    });
  };

  let queryPath = `${process.env.REACT_APP_BASE_URL}/${params.uuid}/${params.slug}/${params["*"]}`;

  let path = `${process.env.REACT_APP_BASE_URL}/${params.uuid}/${params.slug}`;

  useEffect(() => {
    scriptRender(path);

    const updateScreenHeight = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    // Attach the event listener for window resize
    window.addEventListener("resize", updateScreenHeight);

    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  //get script function
  const scriptRender = (path) => {
    setLoading(true);
    axiosClient
      .get(`/api/public/documents?path=${path}`)
      .then((res) => {
        setAccessPage(res.data?.script?.configurations[0]?.access_modes);
        if (res.data?.script?.configurations[0]?.access_modes == 1) {
          if (localStorage.getItem(`SCRIPT_ACCESS_TOKEN_${path}`)) {
            axiosPublicClient
              .get(`/api/scriptAuth/getAccess/?path=${path}`)
              .then((res) => {
                setLoading(false);
                getScriptUserInfo();
              })

              .catch((err) => {
                const response = err.response;
                if (response.status == 401) {
                  localStorage.removeItem(`SCRIPT_ACCESS_TOKEN_${path}`);
                }
                setLoading(false);
                setSignupPopup(true);
                return;
              });
          } else {
            console.log("ok");
            setLoading(false);
            setSignupPopup(true);
            return;
          }
        }

        if (res.data?.script?.configurations[0]?.access_modes == 0) {
          getScriptUserInfo();
        }

        if (!res.data.script.is_published) {
          navigate("/underMaintenance");
        }
        const colors = res.data?.customized_script?.side_bar_colorCode;
        if (colors) {
          const lumi = getLuminance(colors);
          const isColorDark = lumi < 0.5;
          const darkenedColor = darken(0.3, colors);

          const lightenedColor = lighten(0.3, colors);
          setColorCheck(isColorDark);
          setTextBackground(isColorDark ? lightenedColor : darkenedColor);
        }
        setCustomizeColor(res.data.customized_script);
        setLoading(false);
        setPages(res.data.hierarchy);
        setScript(res.data.script);
        let url = new URL(res.data.hierarchy[0].path);
        let paths = url.pathname;
        if (!params["*"]) {
          navigate(paths);
          // let para = `${process.env.REACT_APP_BASE_URL}${paths}`;
          let para = `${process.env.REACT_APP_BASE_URL}${paths}`;

          pageRender(para);
        } else {
          pageRender(queryPath);
        }
      })
      .catch((err) => {
        const response = err.response;
        console.log("Error:", err);
        setLoading(false);
      });
  };

  //page render function

  const pageRender = (para) => {
    getComments(para);
    setLoading(false);
    setOnThisPage(null);
    setIsLoading(true);
    axiosClient
      .get(`/api/public/pages?path=${para}`)
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          setLoadPage(res.data.publicUrl);
          setEditorValue(res.data.publicUrl.content);
          setParentOpen(res.data.parentPages);
          const headers = res.data?.publicUrl.content.blocks.filter(
            (block) => block.type === "header"
          );
          const headerValuesWithId = headers.map((header) => ({
            id: header.id,
            text: header.data.text.replace(/<[^>]*>/g, ""),
          }));
          setOnThisPage(headerValuesWithId);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        const response = err.response;
        if (response && response?.status === 404) {
          navigate("/underMaintenance");
        }
        if (response && response?.status === 302) {
          showToastMessage(response.data.msg);
          let url = new URL(
            `${process.env.REACT_APP_BASE_URL}/${params.uuid}/${params.slug}`
          );
          let paths = url.pathname;
          navigate(paths);
          params["*"] = "";
          let reRender = `${process.env.REACT_APP_BASE_URL}/${params.uuid}/${params.slug}`;
          scriptRender(reRender);
        }
        console.log("Error:", err);
      });
  };

  const getScriptUserInfo = () => {
    axiosPublicClient
      .get(`/api/user/getScriptUserInfo`)
      .then((res) => {
        console.log(res);
        setScriptUserInfo(res.data.userInfo);
        setUsername(res.data.userInfo.username);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //getComment api

  const getComments = (path) => {
    axiosPublicClient
      .get(`/api/comments/getComment?path=${path}`)
      .then((res) => {
        setComments(res.data.hierarchy);
      })
      .catch((err) => {
        const response = err.response;
        console.log("Error:", err);
      });
  };

  //insertCommentAPi

  const handleAddComment = () => {
    setCommentAddLoading(true);

    let payLoad = {
      page_uuid: loadPage?.uuid,
      comments: commentValue,
    };

    if (commentValue !== null) {
      axiosPublicClient
        .post(`/api/comments/postComments`, payLoad)
        .then((res) => {
          getComments(queryPath);
          setCommentValue(null);
          setCommentAddLoading(false);
        })
        .catch((err) => {
          const response = err.response;
          setCommentAddLoading(false);
          if (response.status == 401) {
            console.log("error 401 status");
            setSignupPopup(true);
            showToastMessage("Please Signup to use the comments section");
          }
          console.log("Error:", err);
        });
    } else {
      showToastErrorMessage("Please enter the comments");
      setCommentAddLoading(false);
    }
  };

  //insert reply comment api

  const handleReplyToComment = (uuid, replyComment) => {
    let payLoad = {
      page_uuid: loadPage?.uuid,
      comments: replyComment,
      comment_uuid: uuid,
    };

    setCommentLoading(true);

    if (replyComment !== "") {
      axiosPublicClient
        .post(`/api/comments/postComments`, payLoad)
        .then((res) => {
          setCommentLoading(false);

          getComments(queryPath);
        })
        .catch((err) => {
          const response = err.response;
          if (response.status == 401) {
            setSignupPopup(true);

            showToastMessage("Please Signup to use the comments section");
          }
          setCommentLoading(false);

          console.log("Error:", err);
        });
    } else {
      setCommentLoading(false);

      showToastErrorMessage("Please enter the reply comments");
    }
  };

  const containerStyles = {
    fontFamily: customizeColor?.font_family
      ? customizeColor?.font_family
      : "Inter",
    fontSize: "16px",
  };

  const ejInstance = useRef();

  const [editorValue, setEditorValue] = useState(null);

  const contentPage = (e) => {
    // Assuming the data-set attribute contains the entire URL
    let url = new URL(e.target.dataset.set);

    // Extract the path from the URL
    let targetPath = url.pathname;

    // Navigate to the extracted path
    navigate(targetPath);
    if (targetPath) {
      let para = `${process.env.REACT_APP_BASE_URL}${targetPath}`;

      pageRender(para);
    }
  };

  class CustomTool {
    constructor({ data, api }) {
      this.api = api;
      this.data = data;
      this.container = null;
    }

    static get toolbox() {
      return {
        icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 7h2v6h-2zm0 4h2v2h-2z"/></svg>',
        title: "PublishedURLAttachment",
      };
    }

    static get isReadOnlySupported() {
      return true;
    }

    render() {
      this.container = document.createElement("a");
      this.container.href = this.data.links || "#";
      this.container.textContent = this.data.link;
      this.container.setAttribute("target", "_blank");
      this.container.setAttribute("class", "item");

      return this.container;
    }

    save() {
      return {
        link: this.container.href,
      };
    }
  }

  // Ensure that you've updated your Editor.js configuration to include this tool and set readOnly if needed.

  //search page for published Url
  const handlePageGo = (uuid) => {
    // Assuming the data-set attribute contains the entire URL
    let url = new URL(uuid);

    // Extract the path from the URL
    let searchPath = url.pathname;

    // Navigate to the extracted path
    navigate(searchPath);
    if (searchPath) {
      let para = `${process.env.REACT_APP_BASE_URL}${searchPath}`;
      pageRender(para);
      setsearchPopup(false);
    }
  };

  useEffect(() => {
    if (!serachPopup) {
      setSearchPageData(null);
    }
  }, [serachPopup]);

  //editor

  const initEditor = () => {
    const editor = new EditorJS({
      holder: "editorjs",
      onReady: () => {
        if (editor) {
          ejInstance.current = editor;
        }
      },
      readOnly: true,
      autofocus: false,
      placeholder: "Let`s write an awesome story!",
      data: editorValue,
      onChange: async () => {
        let content = await editor.saver.save();
      },
      tools: {
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },
        header: Header,
        image: {
          class: ImageTool,
        },
        customTool: CustomTool,
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        Marker: {
          class: Marker,
          shortcut: "CMD+SHIFT+M",
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+O",
          config: {
            quotePlaceholder: "Enter a quote",
            captionPlaceholder: "Quote's author",
          },
        },
        embed: {
          class: Embed,
          inlineToolbar: true,
        },
        breakLine: {
          class: BreakLine,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+ENTER",
        },
        attaches: {
          class: AttachesTool,
        },
        underline: Underline,
      },
    });
  };

  const [isPhoneView, setIsPhoneView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsPhoneView(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); //

  // This will run only once

  useEffect(() => {
    const editorElement = document.getElementById("editorjs");

    if (ejInstance.current === null && editorValue) {
      initEditor();
    }

    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, [editorValue]);

  const searchEvent = async (e) => {
    e.preventDefault();

    if (isStreaming) return;

    console.log(e.target.searchValue.value);
    let searchPath = `${process.env.REACT_APP_BASE_URL}/${params.uuid}/${params.slug}`;

    let value = e.target.searchValue.value;

    // await axiosClient
    //   .get(`/api/dashboard/pageSearch/${params.uuid}/${searchPath}?q=${value}`)
    //   .then((res) => {
    //     if (res.data.length > 0) {
    //       setSearchPageData(res.data);
    //     } else {
    //       setSearchPageData(null);
    //     }
    //   })
    //   .catch((err) => {
    //     const response = err.response;
    //     if (response && response?.status === 404) {
    //       setSearchPageData(null);
    //     } else {
    //       console.error("Error:", response?.status);
    //     }
    //   });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setIsStreaming(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/dashboard/pageSearch/${params.uuid}/${searchPath}?q=${value}&path=${searchPath}`,
      requestOptions
    );

    if (!response.ok) {
      setIsStreaming(false);
      return;
    }

    const reader = response.body.getReader();
    // console.log("reader", reader);
    var finalText = "";
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        setIsStreaming(false);
        break;
      }

      const text = new TextDecoder("utf-8").decode(value);
      console.log("Text -- ", text, " finalText ---", finalText);
      finalText += text;
      setSearchPageData(finalText);
    }
    setSearchPageData(finalText);
  };

  const closeOnOutsideClick = (e) => {
    if (
      detail &&
      detailRef.current &&
      !detailRef.current.contains(e.target) &&
      e.target !== iconRef.current
    ) {
      setDetail(false);
    }

    if (
      profileState &&
      profileRef.current &&
      !profileRef.current.contains(e.target) &&
      e.target !== profileIconRef.current
    ) {
      setProfileState(false);
    }
  };

  window.addEventListener("click", closeOnOutsideClick);

  // Cleanup function to remove event listener when component unmounts
  const cleanup = () => {
    window.removeEventListener("click", closeOnOutsideClick);
  };
  const handleExportPDF = async () => {
    if (ejInstance.current) {
      const content = ejInstance?.current?.configuration?.data;
      // Create a container element to render the content
      const container = document.createElement("div");
      container.innerHTML += `<div><h1>${loadPage?.title}</h1></div>`;
      container.innerHTML += `<div><h4>${loadPage?.description}</h4></div>`;

      // Iterate over blocks and add HTML content to the container
      content.blocks.forEach((block) => {
        if (block.type === "header") {
          container.innerHTML += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
        } else if (block.type === "paragraph") {
          container.innerHTML += `<p>${block.data.text}</p>`;
        } else if (block.type === "list") {
          if (block.data.style === "unordered") {
            container.innerHTML += `<ul>${block.data.items
              .map((item) => `<li>${item}</li>`)
              .join("")}</ul>`;
          } else if (block.data.style === "ordered") {
            container.innerHTML += `<ol>${block.data.items
              .map((item) => `<li>${item}</li>`)
              .join("")}</ol>`;
          }
        } else if (block.type === "image") {
          container.innerHTML += `<img src="${block.data.url}" alt="${block.data.caption}">`;
        }
        // Add more block types as needed
      });

      // Generate a PDF using html2pdf.js
      html2pdf(container, {
        margin: 10,
        filename: `${script?.title.toLowerCase()}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      });
    }
  };

  //change popup signup to signin popup
  const changeState = (stateValue) => {
    if (stateValue == "signin") {
      setSigninPopup(true);
      setSignupPopup(false);
    } else if (stateValue == "signup") {
      setSigninPopup(false);
      setSignupPopup(true);
    } else if (stateValue == "forgotPassword") {
      setSigninPopup(false);
    }
  };

  //signup Functionalities

  const onFinish = (values) => {
    let check = Object.keys(values);

    let payLoad = {
      data: values,
      key: accessPage ? "accessPath" : "commentPath",
      path: `${process.env.REACT_APP_BASE_URL}/${params.uuid}/${params.slug}`,
    };
    setPopUpLoading(true);

    if (check.length == 3) {
      axiosClient
        .post("/api/scriptAuth/register", payLoad)
        .then((res) => {
          if (res.status == 200) {
            showToastMessage("Please verify your Email");
            setPopUpLoading(false);
            // setSignupPopup(false)
          }
        })
        .catch((err) => {
          const response = err.response;
          if (response?.status == 400) {
            setEmailError("This email has already been taken.");
          }
          if (response?.status == 403) {
            showToastErrorMessage("Access denied for this email.");
          }

          setPopUpLoading(false);
        });
    }
    if (check.length == 1) {
      axiosClient
        .post("/api/scriptAuth/forgotPassword", payLoad)
        .then((res) => {
          if (res.status == 200) {
            showToastMessage("reset link send to the your email");
            setPopUpLoading(false);
          }
        })
        .catch((err) => {
          const response = err.response;
          if (response?.status == 400) {
            setEmailError("This email has already been taken.");
          }
          setPopUpLoading(false);
        });
    }
    if (check.length == 2) {
      axiosClient
        .post("/api/scriptAuth/login", payLoad)
        .then((res) => {
          if (res.status == 200) {
            localStorage.setItem(
              `SCRIPT_ACCESS_TOKEN_${path}`,
              res.data.access_token
            );

            showToastMessage("successfully logged in");
            setPopUpLoading(false);
            setSigninPopup(false);

            scriptRender(path);
          }
        })
        .catch((err) => {
          console.log(err);
          const response = err.response;

          if (response?.status == 401) {
            let verify = Object.keys(response?.data);
            if (verify[0] == "email") {
              setEmailError(response.data.email);
            }
            if (verify[0] == "password") {
              setPasswordError(response.data.password);
            }
          }

          if (response?.status == 403) {
            showToastErrorMessage("Access denied for this email.");
          }
          setPopUpLoading(false);
        });
    }
  };

  //comment delete

  const handleCommentDelete = (uuid) => {
    axiosPublicClient
      .delete(`/api/comments/deleteComments?id=${uuid}`)
      .then((res) => {
        getComments(queryPath);
      })
      .catch((err) => {
        const response = err.response;
        console.log("Error:", err);
      });
  };

  //comment edit

  const handleEditComment = (uuid, editedComment) => {
    let payLoad = {
      page_uuid: loadPage?.uuid,
      comments: editedComment,
      comment_uuid: uuid,
    };

    axiosPublicClient
      .put(`/api/comments/editComment`, payLoad)
      .then((res) => {
        getComments(queryPath);
      })
      .catch((err) => {
        const response = err.response;
        console.log("Error:", err);
      });
  };

  //logout

  const onLogout = () => {
    localStorage.removeItem(`SCRIPT_ACCESS_TOKEN_${path}`);
    window.location.reload();
  };

  const [image, setImage] = useState(null);

  const handleUserDetail = () => {
    if (username) {
      // setLoading(true);
      const formData = new FormData();
      formData.append("username", username);
      formData.append("image", image); // Assuming you have an 'avatar' file to upload
      console.log(image);
      axiosPublicClient
        .put("/api/user/scriptUserUpdateProfile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          showToastMessage(data.message);
          // setLoading(false);
          setProfilePopup(false);
        })
        .catch((err) => {
          const response = err.response;
          console.error("Error:", response.status);
        });
    } else {
      showToastErrorMessage("please fill the input field");
    }
  };

  // scriptUserUpdateProfile

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: `100vh`,
          }}
        >
          <div role="status" className="">
            <svg
              aria-hidden="true"
              className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="">
          <div
            className={`flex justify-between items-center py-[20px] px-[30px] border-b-[1px] `}
            style={{
              backgroundColor: customizeColor?.header_colorCode || "white",
            }}
          >
            <div className="flex items-center space-x-2">
              {isPhoneView && (
                <i
                  className="fa-solid fa-bars text-gray-600"
                  onClick={() => setOpenUrlSideNave("block")}
                ></i>
              )}

              {script?.logo ? (
                <img src={script?.logo} alt="" className="w-8 " />
              ) : (
                <i className="fa-regular text-slate-600 fa-image text-2xl cursor-pointer pr-1"></i>
              )}
              <p
                className="font-bold text-2xl phone:text-sm font-inter"
                style={{
                  color: customizeColor?.font_colorCode,
                  fontFamily: customizeColor?.font_family,
                }}
              >
                {script && script.title}
              </p>
            </div>
            <div className="flex justify-between space-x-6 items-center">
              <div
                className="pl-6 flex"
                style={{ fontFamily: customizeColor?.font_family }}
              >
                <input
                  type="search"
                  id="search-dropdown"
                  className="block p-[10px] phone:p-[5px] w-[170px] phone:w-[90px]  text-sm text-gray-900 bg-white rounded-lg focus:outline-slate-300  placeholder-gray-400 dark:text-white cursor-pointer border-[1px] "
                  placeholder="Search here"
                  autoComplete="off"
                  required
                  readOnly
                  onClick={() => setsearchPopup((prevState) => !prevState)}
                  ref={searchInpRef}
                />
                <div className="">
                  <button
                    type="submit"
                    className=" p-2 text-sm font-medium h-full text-white bg-[#99a5b8] rounded-r-lg  focus:outline-none "
                  >
                    <i className="fa-solid fa-magnifying-glass "></i>
                    <span className="sr-only">Search</span>
                  </button>
                </div>
              </div>

              <Tooltip title="Print as the current page">
                <button
                  className=""
                  onClick={handleExportPDF}
                  style={{ color: customizeColor?.font_colorCode }}
                >
                  <i
                    className="fa-solid fa-print text-[20px] text-[#a3aec2]"
                    style={{ color: customizeColor?.font_colorCode }}
                  ></i>
                </button>
              </Tooltip>

              <div className="flex items-center ">
                <div className="relative group ">
                  <Tooltip title="Info">
                    <i
                      className=" fa-solid fa-circle-info cursor-pointer text-[20px] text-[#a3aec2] "
                      ref={iconRef}
                      onClick={() => setDetail((prevState) => !prevState)}
                      style={{ color: customizeColor?.font_colorCode }}
                    ></i>
                  </Tooltip>

                  {detail && (
                    <div
                      className="bg-white w-64 py-5 px-4  absolute top-8.5 border-[1px] right-[-10px] z-30 shadow-md rounded-lg "
                      ref={detailRef}
                    >
                      <p
                        className="text-[#69747e] text-sm pt-1"
                        style={{ fontFamily: customizeColor?.font_family }}
                      >
                        <span className="font-medium text-[#25282b] text-sm ">
                          Created At :
                        </span>{" "}
                        {formattedTime}
                      </p>
                      <p
                        className="text-[#69747e] text-sm pt-1"
                        style={{ fontFamily: customizeColor?.font_family }}
                      >
                        <span className="font-medium text-[#25282b] text-sm">
                          Last Modified At :
                        </span>{" "}
                        {formattedTimes}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {localStorage.getItem(`SCRIPT_ACCESS_TOKEN_${path}`) && (
                <div className="cursor-pointer" onClick={handleProfile}>
                  <Tooltip title="Profile">
                    {scriptUserInfo?.avatar ? (
                      <img
                        src={scriptUserInfo?.avatar}
                        className="w-10 h-10 rounded-full"
                        alt=""
                        ref={profileIconRef}
                      />
                    ) : (
                      <i
                        className="fa-regular text-[#a3aec2] fa-circle-user text-2xl cursor-pointer pr-3"
                        ref={profileIconRef}
                      ></i>
                    )}
                  </Tooltip>
                </div>
              )}

              {profileState && (
                <div
                  className="bg-white  w-52 absolute top-14 border-[1px] right-[35px] z-30 shadow-md rounded-lg"
                  ref={profileRef}
                >
                  <div className="w-[85%] m-auto pt-[12px] ">
                    <div className="flex justify-center">
                      {scriptUserInfo?.avatar ? (
                        <img
                          src={scriptUserInfo?.avatar}
                          className="w-10 h-10 rounded-full"
                          alt=""
                        />
                      ) : (
                        <i className="fa-regular text-slate-600 fa-circle-user text-2xl cursor-pointer pr-3"></i>
                      )}
                    </div>
                    <div className="flex justify-center ">
                      <p className="text-[12px] text-textPrimary pt-[2px] pb-[10px]">
                        {scriptUserInfo?.email}
                      </p>
                    </div>
                    <hr />{" "}
                    <div
                      className="hover:text-primary py-[10px] flex items-center space-x-2"
                      onClick={() => {
                        setProfilePopup(true);
                        setProfileState(false);
                      }}
                    >
                      <i className="fa-regular fa-circle-user text-[16px]  cursor-pointer "></i>
                      <p className="text-[16px]  cursor-pointer  ">Profile</p>
                    </div>
                    <div className="hover:text-primary  pb-[12px]  flex items-center space-x-2">
                      <i className="fa-solid fa-arrow-right-from-bracket  text-[16px]  cursor-pointer"></i>

                      <p
                        className="text-[16px]   cursor-pointer"
                        onClick={onLogout}
                      >
                        Logout
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className="flex"
            style={{
              height: `calc(100vh - 83px)`,
            }}
          >
            <div
              className={`phone:${openUrlSideNave} phone:absolute top-0 left-0; phone:z-20`}
              style={{
                maxHeight: `calc(${screenHeight}px - 83px)`,
                backgroundColor: customizeColor?.side_bar_colorCode || "white",
              }}
            >
              <div className=" h-[473px] overflow-x-hidden border-r-[1px]">
                {openUrlSideNave == "block" && (
                  <div className="flex items-center space-x-2 pl-2 pt-5 ">
                    <i
                      className="fa-solid fa-xmark pl-3"
                      onClick={() => setOpenUrlSideNave("hidden")}
                    ></i>
                    <p>close</p>
                  </div>
                )}
                <div className="w-[250px]  phone:w-[150px]  pr-[10px] pl-[24px] pt-[20px] ">
                  {page.map((topLevelPage, index) => (
                    <div
                      key={topLevelPage.uuid}
                      id={topLevelPage.page_id}
                      className=""
                    >
                      <PageTree
                        node={topLevelPage}
                        hasSibling={index < page.length - 1}
                        hasParent={false}
                        contentPage={contentPage}
                        parentOpen={parentOpen}
                        cutomizeTextColor={customizeColor?.font_colorCode}
                        cutomizeFontFamily={customizeColor?.font_family}
                        textBackground={textBackground}
                        colorcheck={colorcheck}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-[100%] py-[10px]">
                <a
                  href="http://www.dckapdesk.com"
                  className="flex w-[90%] m-auto rounded-md bg-[#ECEFF1] p-[10px] hover:text-gray-700 text-sm items-center"
                >
                  <img
                    src={"http://i.postimg.cc/WpHcMJD8/new.png"}
                    alt=""
                    className="w-6 mr-[5px]"
                  />
                  Powered by<span className="font-medium pl-1">DCKAPdesk</span>
                </a>
              </div>
            </div>

            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: `calc(100vh - 85px)`,
                  width: "60%",
                }}
              >
                <div role="status" className="">
                  <svg
                    aria-hidden="true"
                    className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-500"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className=" scroll-smooth overflow-auto pt-10  px-14  phone:w-[100%] phone:px-10"
                style={{
                  width: screenWidth > "425" ? "calc(100% - 250px)" : "",
                  maxHeight: `calc(${screenHeight}px - 85px)`,
                }}
              >
                <div className=" flex justify-between">
                  <div>
                    <div className="flex items-center space-x-2">
                      <img
                        className="cursor-pointer w-[25px]"
                        src={
                          loadPage?.emoji
                            ? loadPage?.emoji
                            : `http://icons.getbootstrap.com/assets/icons/emoji-smile.svg`
                        }
                      />
                      <h1
                        className="text-3xl font-bold phone:text-[18px] font-inter  phone:w-[190px] "
                        style={{ fontFamily: customizeColor?.font_family }}
                      >
                        {page?.length == 0
                          ? "Page Name"
                          : loadPage?.title && loadPage?.title.split("-")[0]}
                      </h1>
                    </div>
                    <h4
                      className="text-[20px] my-3 ml-[32px]  font-normal font-inter phone:text-[16px] phone:w-[170px] "
                      style={{ fontFamily: customizeColor?.font_family }}
                    >
                      {page?.length == 0
                        ? "Page description"
                        : loadPage?.description}
                    </h4>
                  </div>
                  <div>
                    {onThisPage?.length > 0 && (
                      <div className="float-right fixed right-4 max-w-[220px] w-[100%]">
                        <p
                          className="font-semibold text-gray-500 text-lg font-inter"
                          style={{ fontFamily: customizeColor?.font_family }}
                        >
                          On This Page
                        </p>
                        <div className="url-scroll h-[500px] overflow-auto">
                          {onThisPage &&
                            onThisPage.map((page) => (
                              <div
                                className="mt-2 cursor-pointer"
                                key={page.id}
                                style={{
                                  fontFamily: customizeColor?.font_family,
                                }}
                              >
                                <a
                                  href={"#" + page.id}
                                  className={`text-[#495057] ${
                                    window.location.hash == "#" + page.id &&
                                    "font-medium text-primary "
                                  } text-sm`}
                                >
                                  {page.text}
                                </a>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  id="editorjs"
                  contentEditable="false"
                  className="mr-40 phone:pl-[30px] font-inter"
                  style={containerStyles}
                ></div>

                <div className="py-2 px-4 w-[70%]  mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                  <textarea
                    id="comment"
                    rows="6"
                    className="px-0 w-[77%] text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800 "
                    placeholder="Write a Discussion..."
                    required
                    value={commentValue ? commentValue : ""}
                    onChange={(e) => setCommentValue(e.target.value)}
                  ></textarea>
                </div>
                <button
                  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                    commentAddLoading && "flex"
                  } `}
                  id={loadPage?.uuid}
                  onClick={handleAddComment}
                >
                  {commentAddLoading && (
                    <>
                      <div role="status" className="pr-2 ">
                        <svg
                          aria-hidden="true"
                          className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-500"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    </>
                  )}
                  Discussion
                </button>

                <div>
                  <Comments
                    comments={comments}
                    onEdit={handleEditComment}
                    handleCommentDelete={handleCommentDelete}
                    onReply={handleReplyToComment}
                    scriptUserInfo={scriptUserInfo}
                    commentLoading={commentLoading}
                  />
                </div>
              </div>
            )}
          </div>

          {profilePopup && (
            <ProfilePagePopup
              profilePopup={profilePopup}
              setProfilePopup={setProfilePopup}
              scriptUserInfo={scriptUserInfo}
              handleUserDetail={handleUserDetail}
              username={username}
              setUsername={setUsername}
              setImage={setImage}
            />
          )}

          {serachPopup && (
            <Search
              searchInpRef={searchInpRef}
              setsearchPopup={setsearchPopup}
              searchEvent={searchEvent}
              searchPageData={searchPageData}
              setSearchPageData={setSearchPageData}
              serachPopup={serachPopup}
              handlePageGo={handlePageGo}
              isStreaming={isStreaming}
            />
          )}

          {signinPopup && (
            <Modal
              open={signinPopup}
              footer={false}
              onCancel={() => setSigninPopup(false)}
            >
              <ModalComponent
                set="Signin"
                setForgotPasswordPopup={setForgotPasswordPopup}
                changeState={changeState}
                // formValues={formValues}
                onFinish={onFinish}
                emailError={emailError}
                setEmailError={setEmailError}
                popUpLoading={popUpLoading}
                passwordError={passwordError}
                setPasswordError={setPasswordError}
              />
            </Modal>
          )}

          {signupPopup && (
            <Modal
              open={signupPopup}
              footer={false}
              onCancel={() => setSignupPopup(false)}
            >
              <ModalComponent
                set="Signup"
                changeState={changeState}
                // formValues={formValues}
                onFinish={onFinish}
                emailError={emailError}
                setEmailError={setEmailError}
                passwordError={passwordError}
                setPasswordError={setPasswordError}
                popUpLoading={popUpLoading}
              />
            </Modal>
          )}

          {forgotPasswordPopup && (
            <Modal
              open={forgotPasswordPopup}
              footer={false}
              onCancel={() => setForgotPasswordPopup(false)}
            >
              <ModalComponent
                set="Forgot Password"
                changeState={changeState}
                emailError={emailError}
                setEmailError={setEmailError}
                passwordError={passwordError}
                popUpLoading={popUpLoading}
                onFinish={onFinish}
              />
            </Modal>
          )}
        </div>
      )}

      <ToastContainer />
    </>
  );
};
