import React, { useEffect, useState } from "react";
import { MergeComponents } from "../MergeComponents/MergeComponents";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../../axios-client";
import { useMyContext } from "../../context/AppContext";
import { ToastContainer, toast } from "react-toastify";
import { PublishPopup } from "../../common/commonComponents/PublishPopup";

export const Merge = () => {
  const params = useParams();

  const navigate = useNavigate();

  const {
    getScript,
    script,
    getScripts,
    setLoading,
    role,
    hasChanges,
    setHasChanges,
    userDetail,
    activeTeamMembers,
    getAllActiveUsers
  } = useMyContext();

  //hooks

  const [inputValue, setInputValue] = useState("");

  const [editorContent, setEditorContent] = useState([]);

  const [description, setDescription] = useState("");

  const [pageId, setPageId] = useState(null);

  const [treeNode, setTreeNode] = useState([]);

  const [renderScript, setRenderScript] = useState({});
  const [teamUuid, setTeamUuid] = useState([]);

  const [pageContent, setPageContent] = useState(null);

  const [particularTitle, setParticularTitle] = useState("");

  const [hoverPageId, setHoverPageId] = useState(null);

  const [particularPageId, setParticularPageId] = useState(null);

  const [editorValue, setEditorValue] = useState([]);

  const [shareState, setShareState] = useState(false);

  const [publish, setPublish] = useState([]);

  const [scriptError, setScriptError] = useState(null);

  const [state, setState] = useState(true);

  const [parentOpen, setParentOpen] = useState(null);

  const [popUp, setPopUp] = useState(null);

  const [isLoading, setIsLoading] = useState(null);

  const [urlCopyPopup, setUrlCopyPopup] = useState(false);
  const [publishedData, setPublishedData] = useState(null);
  const [changeUrlPath, setChangeUrlPath] = useState(null);

  const [revisiedHistory, setRevisiedHistory] = useState([]);
  const [divId, setDivId] = useState("");

  //page count

  const [maintainPageCount, setMaintainPageCount] = useState(null);

  const [publishPopup, setPublishPopup] = useState("");

  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  const [pageDeleteConfirmation, setPageDeleteConfirmation] = useState(null);

  const [selectedText, setSelectedText] = useState("");
  const [commentIconPosition, setCommentIconPosition] = useState({
    x: 0,
    y: 0,
  });

  const [commentPopup, setCommentPopup] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageIds = queryParams.get("pageId");

  useEffect(() => {
    if (pageIds) {
      getParentOpen();
      getParticularPage();
      getParticularScript();
      getScripts();
      getAllActiveUsers();
    } else {
      getParticularScript();
      getScripts();
      getParticularOpenScript();
    }
  }, [pageIds]);

  const handleChange = async (event) => {
    const inputValue = event;

    setInputValue(inputValue); // Update the state with the current value

    let paraId = params.slug;
    const encodedInputValue = inputValue;
    let payload = {
      inputValue: encodedInputValue,
      queryParameter: paraId,
      teamParameter: params.uuid,
    };
    await axiosClient
      .post("/api/scripts/addScriptTitle", payload)
      .then((res) => {
        getScripts();
        getScript();
      })
      .catch((err) => {
        const response = err.response;
        if (response && response?.status === 403) {
          showToastErrorMessage(response.data.errorMsg);
        } else {
          console.error("Error:", response?.status);
        }
      });
  };

  const getParticularPage = async () => {
    setIsLoading(true);
    await axiosClient
      .get(`/api/pages/getPage/${pageIds}`)
      .then((res) => {
        setRevisiedHistory(res.data.revised_history);

        setParticularTitle(res.data.pages.title);
        setDescription(res.data.pages.description);
        setInputStr(res.data.pages.emoji);
        setEditorValue(res.data.pages.content);
        setEditorContent(res.data.pages.content);
        getSelectComments();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getParticularOpenScript = async () => {
    let script_uuid = params.slug;

    await axiosClient
      .get(`/api/dashboard/getScriptAndPage/${script_uuid}`)
      .then((res) => {
        navigate(
          `/dashboard/${params.uuid}/changes/${params.slug}/?pageId=${res.data.hierarchy[0].uuid}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getParentOpen = async () => {
    await axiosClient
      .get(`/api/dashboard/getOpenParent/${pageIds}`)
      .then((res) => {
        setParentOpen(res.data.parentPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const duration = 2000;

  const showToastSaveMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
    });
  };

  const showToastErrorMessage = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
    });
  };

  const getParticularScript = async () => {
    setLoading(true);
    let script_uuid = params.slug;
    await axiosClient
      .get(`/api/dashboard/getScriptAndPage/${script_uuid}`)
      .then((res) => {
        if (res.status == 200) {
          setPublishedData(res.data.getScriptAndPages);
          setChangeUrlPath(res.data.getScriptAndPages.path.split("/")[4]);
          setInputValue(res.data.getScriptAndPages.title);
          setPageContent(res.data.hierarchy[0]);
          setTreeNode(res.data.hierarchy);
          setRenderScript(res.data.getScriptAndPages);
          setPublish(res.data.getScriptAndPages);
          setMaintainPageCount(res.data.pageCount);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSave = () => {
    setLoading(true);
    const postData = {
      id: pageIds,
      script_uuid: params.slug,
      title: particularTitle ? particularTitle : "Page Name",
      description: description ? description : "Page Description",
      content: editorContent,
      emoji: inputStr,
    };

    axiosClient
      .post("/api/pages/updatePageData", postData)
      .then((res) => {
        getParticularScript();
        getParticularPage();
        showToastSaveMessage(res.data.msg);
        setLoading(false);
        setHasChanges(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSaveAndPublish = () => {
    setLoading(true);

    const postData = {
      id: pageIds,
      script_uuid: params.slug,
      title: particularTitle ? particularTitle : "Page Name",
      description: description ? description : "Page Description",
      content: editorContent,
      emoji: inputStr,
    };
    axiosClient
      .post("/api/pages/updatePageData", postData)
      .then((res) => {
        getParticularScript();
        getParticularPage();
        setLoading(false);
        setHasChanges(false);

        contentPublish(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const contentPublish = (checked) => {
    setLoading(true);

    axiosClient
      .get(`/api/public/scripts/${params.slug}/${checked}`)

      .then((res) => {
        setRenderScript(res.data.publicUrl);
        setUrlCopyPopup(true);
        setTeamUuid(params.uuid);
        setLoading(false);
        {
          res.data.publicUrl.is_published
            ? showToastSaveMessage("Save and Published successsfully")
            : showToastErrorMessage(res.data.msg);
        }
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const addPage = async () => {
    setLoading(true);
    await axiosClient
      .post(`/api/pages/addPageData/${params.slug}`)
      .then((res) => {
        getParticularScript();
        setLoading(false);
        showToastSaveMessage(res.data.msg);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const addChildPage = (uuid) => {
    setLoading(true);
    let page_uuid = uuid;
    axiosClient
      .post(`/api/pages/addChildPage/${params.slug}/${page_uuid}`)
      .then((res) => {
        getParticularScript();
        handleParaSave(pageIds, res.data.Pages.uuid);
        showToastSaveMessage(res.data.msg);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const contentPage = async (e) => {
    setHasChanges(false);
    setPageId(e.target.id);
    let pageId = e.target.id;
    handleParaSave(pageIds, pageId);
  };

  const handleScriptMouseEnter = (e) => {
    setHoverPageId(e.target.id);
  };

  const handleScriptMouseLeave = (e) => {
    setHoverPageId(null);
  };

  const handleMore = (e) => {
    setParticularPageId(e.target.id);
    addChildPage(e.target.id);
    setPopUp(null);
    setHoverPageId(null);

    if (localStorage.getItem("mainId")) {
      localStorage.removeItem("mainId");
    }
  };

  const handlePageDelete = async (e) => {
    let targetId = e.target.id;
    setLoading(true);

    if (targetId) {
      await axiosClient
        .delete(`/api/pages/permanentDeletePage/${targetId}`)
        .then((res) => {
          setPageDeleteConfirmation(null);
          if (res.data.page.page_uuid != null) {
            navigate(
              `/dashboard/${params.uuid}/changes/${params.slug}/?pageId=${res.data.page.page_uuid}`
            );
            setLoading(false);
            showToastErrorMessage(res.data.Success);
          } else {
            navigate(`/dashboard/${params.uuid}/changes/${params.slug}/`);
            setLoading(false);
            showToastErrorMessage(res.data.Success);
          }
        })
        .catch((err) => {
          const response = err.response;
          console.error("Error:", response?.status);
          setLoading(false);
        });
    }
    if (localStorage.getItem("mainId")) {
      localStorage.removeItem("mainId");
    }
  };

  const HandleShare = () => {
    setShareState(true);
  };

  const onChange = (checked) => {
    console.log("herhe");
    axiosClient
      .get(`/api/public/scripts/${params.slug}/${checked}`)

      .then((res) => {
        setPublishedData(res.data.publicUrl);
        setTeamUuid(params.uuid);
        {
          res.data.publicUrl.is_published
            ? showToastSaveMessage(res.data.msg)
            : showToastErrorMessage(res.data.msg);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeletePage = () => {
    setPageDeleteConfirmation(null);
    if (localStorage.getItem("mainId")) {
      localStorage.removeItem("mainId");
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log(hasChanges);
      if (hasChanges) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);

  const handleMerge = () => {
    if (!hasChanges) {
      setLoading(true);
      let payLoad = {
        script_uuid: params.slug,
        status: "edit-request",
      };
      axiosClient
        .put(`/api/pages/mergeSourceDataToPublic`, payLoad)
        .then((res) => {
          navigate(`/dashboard/${params.uuid}/edit/${params.slug}`);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      showToastErrorMessage("changes made please save the page!");
    }
  };

  const UrlChangeSave = async () => {
    let payLoad = {
      path: changeUrlPath,
      team_uuid: params.uuid,
      script_uuid: params.slug,
    };

    await axiosClient
      .put("/api/scripts/pathChangeRequest", payLoad)
      .then((res) => {
        console.log(res.data);
        getParticularScript();
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleParaSave = (uuid, targetId) => {
    setLoading(true);

    console.log(particularTitle, "beforeSave");
    if (hasChanges) {
      const postData = {
        id: uuid,
        script_uuid: params.slug,
        title: particularTitle ? particularTitle : "Page Name",
        description: description ? description : "Page Description",
        content: editorContent,
        emoji: inputStr,
      };

      axiosClient
        .post("/api/pages/updatePageData", postData)
        .then((res) => {
          setParticularTitle("");
          setDescription("");
          setEditorContent("");
          setEditorValue("");
          setInputStr("");
          getParticularScript();
          getParticularPage();
          showToastSaveMessage(res.data.msg);
          setLoading(false);
          setHasChanges(false);

          navigate(
            `/dashboard/${params.uuid}/changes/${params.slug}/?pageId=${targetId}`
          );
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      navigate(
        `/dashboard/${params.uuid}/changes/${params.slug}/?pageId=${targetId}`
      );
    }
  };

  const handleMouseUp = () => {
    const selection = window.getSelection();
    if (selection && selection.toString()) {
      setSelectedText(selection.toString());
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const iconHeight = 5; // Adjust this value as needed
      setCommentIconPosition({ x: 1210, y: rect.top - iconHeight });
    } else {
      setSelectedText("");
      setCommentIconPosition({ x: 0, y: 0 });
    }
  };

  const handleSelectComment = (value) => {
    setCommentPopup(value);
  };

  // const [handleComments, setHandleComments] = useState(null);
  // const [allSelectComments, setAllSelectComments] = useState([]);

  // const [handleEditValue, setHandleEditValue] = useState(null);

  // const handleParentComment = (uuid, value) => {
  //   let payLoad = {
  //     id: divId,
  //     page_uuid: pageIds,
  //     content: selectedText ? selectedText : value,
  //     comments: handleComments,
  //     comment_uuid: uuid,
  //   };

  //   axiosClient
  //     .post(`/api/comments/postLineComments`, payLoad)
  //     .then((res) => {
  //       getSelectComments();
  //       setHandleComments(null);
  //       handleSave();
  //       setSelectedText(null);
  //     })

  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getSelectComments = () => {
    axiosClient
      .get(`/api/comments/getLineComments?page_uuid=${pageIds}`)
      .then((res) => {
        setAllSelectComments(res.data.hierarchy);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectedCommentDelete = (uuid) => {
    axiosClient
      .delete(`/api/comments/deleteLineComments?id=${uuid}`)
      .then((res) => {
        getSelectComments();
      })

      .catch((err) => {
        console.log(err);
      });
  };
  // handleSelectedCommentEdit
  const handleSelectedCommentResolve = (uuid) => {
    axiosClient
      .delete(`/api/comments/resolveLineComments?id=${uuid}`)
      .then((res) => {
        getSelectComments();
      })

      .catch((err) => {
        console.log(err);
      });
  };



  const [handleComments, setHandleComments] = useState(null);

  const [selectCommentValue, setSelectCommentValue] = useState("");

  const [selectEditCommentValue, setSelectEditCommentValue] = useState("");

  const [allSelectComments, setAllSelectComments] = useState([]);

  const [handleEditValue, setHandleEditValue] = useState(null);

  const [selectedOption, setSelectedOption] = useState([]);

  const [selectCommentLoading, setSelectedCommentLoading] = useState(false);
  const [selectChildCommentLoading, setSelectedChildCommentLoading] =
    useState(false);

  const handleParentComment = () => {
    setSelectedCommentLoading(true);
    if (selectedOption && selectedOption.id) {
      const uuid = selectedOption.id;
      console.log("Selected UUID:", uuid);
    }

    // Prepare the data to send in the API call
    const data = {
      selectCommentValue,
      selectedOption,
    };

    console.log(data);
    let payLoad = {
      id: divId,
      page_uuid: pageIds,
      content: selectedText ? selectedText : null,
      comments: data,
      comment_uuid: null,
    };
    // console.log(handleComments);
    axiosClient
      .post(`/api/comments/postLineComments`, payLoad)
      .then((res) => {
        getSelectComments();
        setHandleComments(null);
        setSelectCommentValue("");
        setSelectedOption([]);
        handleSave();
        setSelectedText(null);
        setSelectedCommentLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSelectedCommentLoading(false);
      });
  };

  const handleChildComment = (uuid, value) => {
    setSelectedChildCommentLoading(true);
    if (selectedOption && selectedOption.id) {
      const uuid = selectedOption.id;
      console.log("Selected UUID:", uuid);
    }

    // Prepare the data to send in the API call
    const data = {
      selectEditCommentValue,
      selectedOption,
    };

    console.log(data);
    let payLoad = {
      id: divId,
      page_uuid: pageIds,
      content: selectedText ? selectedText : value,
      comments: data,
      comment_uuid: uuid,
    };
    // console.log(handleComments);
    axiosClient
      .post(`/api/comments/postLineComments`, payLoad)
      .then((res) => {
        getSelectComments();
        setHandleComments(null);
        setSelectEditCommentValue("");
        setSelectedOption([]);
        // handleSave();
        setSelectedText(null);
        setSelectedChildCommentLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSelectedChildCommentLoading(false);
      });
  };



















  return (
    <div>
      <MergeComponents
        changeEvent={handleChange}
        inputValue={inputValue}
        setInputValue={setInputValue}
        renderScript={renderScript}
        HandleShare={HandleShare}
        publish={publish}
        role={role}
        getParticularScript={getParticularScript}
        treeNode={treeNode}
        addPage={addPage}
        contentPage={contentPage}
        pageContent={pageContent}
        particularTitle={particularTitle}
        setParticularTitle={setParticularTitle}
        description={description}
        setDescription={setDescription}
        handleScriptMouseEnter={handleScriptMouseEnter}
        handleScriptMouseLeave={handleScriptMouseLeave}
        hoverPageId={hoverPageId}
        handleMore={handleMore}
        handleSave={handleSave}
        shareState={shareState}
        setShareState={setShareState}
        onChange={onChange}
        editorValue={editorValue}
        setEditorValue={setEditorValue}
        editorContent={editorContent}
        setEditorContent={setEditorContent}
        parentOpen={parentOpen}
        teamUuid={teamUuid}
        setHoverPageId={setHoverPageId}
        popUp={popUp}
        setPopUp={setPopUp}
        handlePageDelete={handlePageDelete}
        isLoading={isLoading}
        maintainPageCount={maintainPageCount}
        inputStr={inputStr}
        setInputStr={setInputStr}
        showPicker={showPicker}
        setShowPicker={setShowPicker}
        setPageDeleteConfirmation={setPageDeleteConfirmation}
        clickPublish={handleSave}
        handleDeletePage={handleDeletePage}
        pageDeleteConfirmation={pageDeleteConfirmation}
        handleMerge={handleMerge}
        setPublishPopup={setPublishPopup}
        revisiedHistory={revisiedHistory}
        handleSelectComment={handleSelectComment}
        handleMouseUp={handleMouseUp}
        commentIconPosition={commentIconPosition}
        selectedText={selectedText}
        setSelectedText={setSelectedText}
        commentPopup={commentPopup}
        setCommentPopup={setCommentPopup}
        handleComments={handleComments}
        setHandleComments={setHandleComments}
        handleParentComment={handleParentComment}
        allSelectComments={allSelectComments}
        handleSelectedCommentDelete={handleSelectedCommentDelete}
        handleSelectedCommentResolve={handleSelectedCommentResolve}
        setHandleEditValue={setHandleEditValue}
        getSelectComments={getSelectComments}
        userDetail={userDetail}
        setDivId={setDivId}
        divId={divId}
        activeTeamMembers={activeTeamMembers}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        selectCommentValue={selectCommentValue}
        setSelectCommentValue={setSelectCommentValue}
        selectEditCommentValue={selectEditCommentValue}
        setSelectEditCommentValue={setSelectEditCommentValue}
        handleChildComment={handleChildComment}
        selectChildCommentLoading={selectChildCommentLoading}

      />
      {publishPopup && (
        <PublishPopup
          publishedData={publishedData}
          changeUrlPath={changeUrlPath}
          setChangeUrlPath={setChangeUrlPath}
          UrlChangeSave={UrlChangeSave}
          onChange={onChange}
        />
      )}
    </div>
  );
};
