import axios from "axios";

const axiosPublicClient = axios.create({
  // baseURL: "https://docsapi.klizer.com",
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://kbbackend.dckapdesk.com",
});

const url = window?.location?.href;
const lastSlashIndex = url.lastIndexOf("/"); // Find the last occurrence of '/'
const extractedUrl = url.substring(0, lastSlashIndex); // Extract substring from the start to the last occurrence of '/'

axiosPublicClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(`SCRIPT_ACCESS_TOKEN_${extractedUrl}`);
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axiosPublicClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    try {
      const { response } = error;
    } catch (e) {
      console.error("Unexpected error:", e);
      throw e;
    }

    // If no refresh token or unsuccessful refresh, throw the original error
    throw error;
  }
);

export default axiosPublicClient;
