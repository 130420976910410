import React, { useState } from "react";
import { ColorPicker, Select, theme } from "antd";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import axiosClient from "../axios-client";
// import ThemeObject from "../theme";
import HashLoader from "react-spinners/HashLoader";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";

export const CustomizePage = (props) => {
  const params = useParams();
  const [headerColor, setHeaderColor] = useState(null);
  const [headerColorCode, setHeaderColorCode] = useState(null);
  const [sideBarColor, setSideBarColor] = useState(null);
  const [sideBarColorCoder, setSideBarColorCode] = useState(null);
  const [textColor, setTextColor] = useState(null);
  const [textColorCode, setTextColorCode] = useState(null);

  const [contrastColor, setContrastColor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ThemeObject, setThemeObject] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [fontFamily, setFontFamily] = useState("Inter");
  const duration = 2000;

  const [themeDetail, setThemeDetail] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const path = queryParams.get("key");
  let scriptSlug = path.split("/")[4];
  const [hasCustomizeChanges, setHasCustomizeChanges] = useState(false);

  useEffect(() => {
    getAllDefaultThemes();
    scriptRender(path);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasCustomizeChanges) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasCustomizeChanges]);

  const handleCloseClick = (e) => {
    console.log(hasCustomizeChanges);
    if (hasCustomizeChanges) {
      const confirmed = window.confirm(
        "Are you sure want to Leave? you have unsaved changes"
      );
      if (confirmed) {
        setHasCustomizeChanges(false);
        let newUrl = new URL(e.target.id, window.location.origin); // Providing base URL
        let newPath = newUrl.pathname;
        navigate(newPath);
      } else {
        e.preventDefault();
        return false;
      }
    } else {
      setHasCustomizeChanges(false);
      let newUrl = new URL(e.target.id, window.location.origin); // Providing base URL
      let newPath = newUrl.pathname;
      navigate(newPath);
    }
  };

  const showToastMessages = (data) => {
    toast.success(data, {
      position: toast.POSITION.RIGHT,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
    });
  };

  const scriptRender = (path) => {
    setLoading(true);

    axiosClient
      .get(`/api/public/documents?path=${path}`)
      .then((res) => {
        if (!res.data.script.is_published) {
          navigate("/underMaintenance");
        }
        setLoading(false);
        setHeaderColorCode(res.data.customized_script.header_colorCode);
        setSideBarColorCode(res.data.customized_script.side_bar_colorCode);
        setTextColorCode(res.data.customized_script.font_colorCode);
        setFontFamily(res.data.customized_script.font_family);
      })
      .catch((err) => {
        const response = err.response;

        console.error("Error:", response?.status);
        setLoading(false);
      });
  };

  const handleHeaderColorChange = (color, event) => {
    setHeaderColor(event.slice(1));
    setHeaderColorCode(event);
    setHasCustomizeChanges(true);
  };

  const handleSideBarColorChange = (color, event) => {
    setSideBarColor(event.slice(1));
    calculateContrastColor(event);
    setSideBarColorCode(event);
    setHasCustomizeChanges(true);

    // You can use the selected color value as needed
  };

  const handleTextColorChange = (color, event) => {
    setTextColor(event.slice(1));
    setTextColorCode(event);
    setHasCustomizeChanges(true);

    // You can use the selected color value as needed
  };

  const handleSumbitColorCode = () => {
    setLoading(true);
    let URL = window?.location?.href;
    let scriptUrl = URL.split("/");

    // Remove the segment at index 3
    scriptUrl.splice(3, 1);

    // Join the modified array to get the new URL
    let modifiedURL = scriptUrl.join("/");

    let payload = {
      uuid: params.slug,
      header_colorCode: "#" + headerColor,
      side_bar_colorCode: "#" + sideBarColor,
      font_colorCode: "#" + textColor,
      font_family: fontFamily,
    };

    axiosClient
      .post("/api/scripts/customizedLayoutScripts", payload)
      .then((res) => {
        setLoading(false);
        setHasCustomizeChanges(false);
        showToastMessages(res.data.msg);
      })
      .catch((err) => {
        const response = err.response;
        setLoading(false);

        console.error("Error:", response?.status);
      });
  };
  const calculateContrastColor = (backgroundColor) => {
    const hexColor = backgroundColor.replace("#", "");
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    setContrastColor(brightness > 128 ? "000" : "fff");
  };

  const themeSetting = (theme) => {
    setHasCustomizeChanges(true);
    setHeaderColor(theme.header_colorCode.slice(1));
    setHeaderColorCode(theme.header_colorCode);
    setSideBarColor(theme.side_bar_colorCode.slice(1));
    setSideBarColorCode(theme.side_bar_colorCode);

    setTextColor(theme.font_colorCode.slice(1));
    setTextColorCode(theme.font_colorCode);
  };

  const getAllDefaultThemes = () => {
    axiosClient
      .get("/api/dashboard/getAllDefaultThemes")
      .then((res) => {
        setThemeObject(res.data.themes);
      })
      .catch((err) => {
        const response = err.response;

        console.error("Error:", response?.status);
      });
  };

  const toggleThemeTabs = (tabStatus) => {
    setActiveTab(tabStatus);
  };

  const onChange = (value) => {
    setFontFamily(value);
    setHasCustomizeChanges(true);
  };

  const options = [
    { value: "Inter", label: "Inter" },
    {
      value: "Roboto",
      label: "Roboto",
    },
    {
      value: "Roboto Slab",
      label: "Roboto Slab",
    },
    {
      value: "Open Sans",
      label: "Open Sans",
    },
    {
      value: "Ubuntu",
      label: "Ubuntu",
    },
    {
      value: "Source Sans Pro",
      label: "Source Sans Pro",
    },
    {
      value: "Lato",
      label: "Lato",
    },
    {
      value: "Overpass",
      label: "Overpass",
    },
    {
      value: "Noto Sans",
      label: "Noto Sans",
    },
    {
      value: "Poppins",
      label: "Poppins",
    },
    {
      value: "IBM Plex Serif",
      label: "IBM Plex Serif",
    },
    {
      value:
        "'gitbook-content-font', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif",
      label: "Helvetica",
    },
  ];
  const navigate = useNavigate();

  const handleBack = (e) => {
    let newUrl = new URL(e.target.id, window.location.origin); // Providing base URL
    let newPath = newUrl.pathname;
    navigate(newPath);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      <div className="flex ">
        <div className=" w-[50%] border-r-[1px] border-r-gray-150">
          <div className="w-[90%] m-auto">
            <div className=" ">
              <p className="text-xl font-medium font-inter py-8">
                Section Customization
              </p>
            </div>
            <div className="flex space-x-4">
              <p
                className={`cursor-pointer ${
                  activeTab === 1 ? "text-black  font-inter" : "text-gray-400"
                }`}
                onClick={() => toggleThemeTabs(1)}
              >
                Default Themes
              </p>
              <p className="border-2 border-inherit"></p>
              <p
                className={`cursor-pointer  ${
                  activeTab === 0 ? "text-black  font-inter" : "text-gray-400"
                }`}
                onClick={() => toggleThemeTabs(0)}
              >
                Customized Colors
              </p>
            </div>
          </div>
          <div className="w-[100%] border-b-[1px] border-b-gray-150 py-[1px]"></div>
          <div className="bg-gray-100 h-[calc(100%-92px)]">
            <div className="w-[90%] m-auto">
              {activeTab && activeTab ? (
                <div className="pt-5">
                  <div className="border-[1px] w-[100%] h-[300px]  border-gray-300 bg-white rounded-lg ">
                    <div className="w-[90%] m-auto py-3">
                      <div className="">
                        <p className="font-medium">Default Themes</p>
                      </div>
                    </div>
                    <div className="w-[100%] border-b-[1px] border-b-gray-150"></div>
                    <div className="w-[90%] m-auto py-6">
                      <div className="grid grid-cols-3 gap-[18px]">
                        {ThemeObject &&
                          ThemeObject.map((value, index) => (
                            <div
                              key={value.id}
                              onClick={() => themeSetting(value)}
                              className="border-[1px] h-[80px] border-gray-300 bg-white rounded-lg cursor-pointer"
                            >
                              <div
                                className={`box-header w-[100%] py-[7px] rounded-t-lg`}
                                style={{
                                  backgroundColor: `${value.header_colorCode}`,
                                }}
                              ></div>
                              <div
                                className={`box-side-bar w-[30%] h-[64px] rounded-bl-lg py-[5px] border-r-[1px] border-gray-100 `}
                                style={{
                                  backgroundColor: `${value.side_bar_colorCode}`,
                                }}
                              >
                                <div
                                  className="line w-[70%] h-[2px] bg-white m-auto mb-1"
                                  style={{
                                    backgroundColor: `${value.font_colorCode}`,
                                  }}
                                ></div>
                                <div
                                  className="line w-[70%] h-[2px] bg-white m-auto mb-1"
                                  style={{
                                    backgroundColor: `${value.font_colorCode}`,
                                  }}
                                ></div>
                                <div
                                  className="line w-[70%] h-[2px] bg-white m-auto mb-1"
                                  style={{
                                    backgroundColor: `${value.font_colorCode}`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="border-[1px] w-[100%] h-[130px]  border-gray-300 bg-white rounded-lg ">
                      <div className="w-[90%] m-auto py-3">
                        <div className="">
                          <p className="font-medium">Styling</p>
                        </div>
                      </div>
                      <div className="w-[100%] border-b-[1px] border-b-gray-150"></div>
                      <div className="w-[90%] m-auto py-6 flex space-x-8">
                        <p className="py-1 font-medium">Font Family</p>
                        <Select
                          showSearch
                          placeholder="Select a font family"
                          optionFilterProp="children"
                          style={{
                            width: 160,
                          }}
                          value={fontFamily}
                          onChange={onChange}
                          filterOption={filterOption}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="py-5">
                  <div className="border-[1px] w-[100%] h-[400px]  border-gray-300 bg-white rounded-lg ">
                    <div className="w-[90%] m-auto py-3">
                      <div className="">
                        <p>Customize Color</p>
                      </div>
                    </div>
                    <div className="w-[100%] border-b-[1px] border-b-gray-150"></div>
                    <div className="w-[90%] m-auto py-6">
                      <div className="flex flex-col space-y-8">
                        <div className="border-[1px] w-[100%] h-[80px]  border-gray-300 bg-white rounded-lg ">
                          <div className={`w-[90%] m-auto py-2 `}>
                            <p>Header</p>

                            <ColorPicker
                              defaultValue="#1677ff"
                              allowClear
                              value={headerColorCode}
                              onChange={handleHeaderColorChange}
                            />
                          </div>
                        </div>
                        <div className="border-[1px] w-[100%] h-[80px]  border-gray-300 bg-white rounded-lg ">
                          <div className="w-[90%] m-auto py-2">
                            <p>SideBar</p>
                            <ColorPicker
                              defaultValue="#1677ff"
                              allowClear
                              value={sideBarColorCoder}
                              onChange={handleSideBarColorChange}
                            />
                          </div>
                        </div>
                        <div className="border-[1px] w-[100%] h-[80px]  border-gray-300 bg-white rounded-lg ">
                          <div className="w-[90%] m-auto py-2">
                            <p>Text Color</p>
                            <ColorPicker
                              defaultValue="#1677ff"
                              allowClear
                              value={textColorCode}
                              onChange={handleTextColorChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className=" w-[100%]">
          <div className="w-[80%] m-auto pt-5">
            <div className="text-end cursor-pointer">
              <i
                className="fa-solid fa-x"
                id={`dashboard/${params.uuid}/edit/${params.slug}`}
                onClick={(e) => handleCloseClick(e)}
              ></i>
            </div>
          </div>
          <div className="w-[90%] m-auto">
            <div className=" pt-20 flex justify-center items-center h-[calc(100vh - 40px)] flex-col">
              <div
                className="border-[1px] border-gray-300 bg-white rounded-lg shadow-sm"
                id="wrap"
              >
                {console.log(headerColor, sideBarColor, "wow")}

                <iframe
                  id="frame"
                  src={`${process.env.REACT_APP_BASE_URL}/preview/${params.uuid}/${scriptSlug}?header_colorCode=${headerColor}&side_bar_colorCode=${sideBarColor}&font_colorCode=${textColor}&font_family=${fontFamily}`}
                ></iframe>
              </div>
              <div className="flex justify-end w-[100%]">
                <div className="flex items-center justify-end space-x-6  pt-10">
                  <div className={`text-center`}>
                    <button
                      className="text-gray-600 font-bold py-1.5 px-5 rounded border border-gray-300 hover:border-primary hover:text-primary"
                      id={`dashboard/${params.uuid}/edit/${params.slug}`}
                      onClick={(e) => handleCloseClick(e)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className={`text-center`}>
                    <button
                      className="bg-primary  text-white font-bold py-1.5 px-5 rounded"
                      onClick={handleSumbitColorCode}
                    >
                      Save
                    </button>
                  </div>
                </div>{" "}
                <ToastContainer />
              </div>
            </div>
            {loading && (
              <>
                <div className="bg-[#aeaeca] opacity-[0.5] w-[100%] h-[100vh] absolute top-0 left-0  z-10"></div>
                <div className="">
                  <p className="absolute top-[48%] left-[48%] z-50 ">
                    <HashLoader color="#3197e8" />
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
