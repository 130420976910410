import React, { useEffect, useState } from "react";
import { Table } from "antd";
import axiosClient from "../../axios-client";
import { useParams } from "react-router-dom";
import { Checkbox } from 'antd';
export const TableComponent = ({ accessUsers, setAccessUsers,handleRemove,handleCheckboxChange,selectedIds }) => {
  const params = useParams();



  const columns = [
    {
      title: "Select",
      dataIndex: "select",
      render: (_, record) => (
        <Checkbox
          id={`${record.email}`}
          onChange={(e) => handleCheckboxChange(e, record)}
          checked={selectedIds.includes(record.email)}
        />
      ),
      width: "10%",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => <a>{text}</a>,
      width: "50%",
    },
    {
      title: "Remove",
      className: "column-money",
      dataIndex: "email", // Use 'id' as dataIndex
      align: "left",
      render: (
        email // Pass 'email' as argument to the onClick function
      ) => <a onClick={() => handleRemove(email)} style={{ color: 'red' }}>{"Remove"}</a>,
      width: "20%",
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={accessUsers}
      bordered
      pagination={{ pageSize: 3 }}
      scroll={{ x: 400 }}
    />
  );
};
