import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useMyContext } from "../../context/AppContext";
export const ViewHeader = (props) => {
  const params = useParams();
  // const dataToPass = 'Hello from source component';

  const { setHasChanges, openSideNave, setOpenSideNave } = useMyContext();

  const [scriptModifiedPath, setModifiedScriptPath] = useState(null);
  const [popUp, setPopUp] = useState(false);

  let duration = 2000;
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
    });
  };
  const copyLink = () => {
    let path = `${props.renderScript.path}`;
    navigator.clipboard.writeText(path);
    showToastMessage("Link Copied !");
  };

  useEffect(() => {
    let scriptPath = props.renderScript?.path && props.renderScript.path;
    console.log(scriptPath, "welcome");
    if (scriptPath) {
      // Assuming you want to add "customize" at the 3rd index of the path array
      let pathArray = scriptPath.split("/");
      pathArray.splice(3, 0, "customize");
      scriptPath = pathArray.join("/");
      console.log(scriptPath);
      setModifiedScriptPath(scriptPath);
    }
  }, [props.renderScript]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageIds = queryParams.get("pageId");
  const handleSideBar = () => {
    setOpenSideNave("block");
  };
  return (
    <div className="bg-[#ffff] w-[100%] ">
      <div
        className={`flex items-center m-auto justify-between relative w-[100%]  2xl:py-[30px] py-[10px]  px-[30px] phone:px-[10px] shadow-sm`}
      >
        <div
          className="2xl:hidden xl:hidden lg:hidden  phone:block phone:text-[12px] "
          onClick={handleSideBar}
        >
          <i className="fa-solid fa-bars"></i>
        </div>
        <div className="flex  items-center  space-x-2 ml-2">
          {props.renderScript?.logo ? (
            <img
              className="cursor-pointer w-[25px]"
              src={props.renderScript?.logo && props.renderScript?.logo}
            />
          ) : (
            <i className="fa-regular fa-image text-slate-600 text-2xl cursor-pointer pr-1"></i>
          )}

          <input
            className="text-textPrimary font-bold font-sans text-2xl   bg-[#EEEEEE] focus:outline-gray-300 w-80 h-11  phone:h-8 pl-2 phone:w-[160px] rounded"
            value={props.inputValue}
            readOnly={true}
          />
        </div>
        {props.role !== 2 && (
          <div className="flex items-center justify-between">
            <button
              type="button"
              className="text-white bg-primary hover:bg-sky-600 font-medium text-sm h-9 w-24  focus:outline-none rounded mr-2 mb-2"
              onClick={copyLink}
            >
              Copy Link
            </button>
            <Link to={`/dashboard/${params.uuid}/changes/${params.slug}`}>
              <button
                type="button"
                className="text-textPrimary border-[1px] border-gray-400 phone:text-sm font-medium rounded-lg text-sm h-9 w-24  phone:w-16 phone:h-7 mr-2 mb-2 "
                onClick={props.handleEdit}
                disabled={props.role == 2 ? true : false}
              >
                Edit
              </button>
            </Link>

            {props.role !== 2 && (
              <div className="relative">
                <span
                  className="material-symbols-outlined pb-2 text-[#535455] cursor-pointer text-2xl phone:text-[14px] leading-[6px]"
                  onClick={() => setPopUp((prev) => !prev)}
                  title="Manage publish"
                >
                  more_vert
                </span>
                {popUp && (
                  <div
                    className="bg-white w-36 py-3 px-3  absolute top-8.5 border-[1px] right-[-10px] z-30 shadow-md rounded-lg "
                    // ref={detailRef}
                  >
                    <p
                      className="text-[#69747e] text-sm my-2 cursor-pointer  "
                      onClick={() => {
                        props.setPublishPopup((prev) => !prev);
                        setPopUp((prev) => !prev);
                      }}
                    >
                      <span className="font-medium text-[#25282b] text-sm hover:text-primary">
                        Manage Setting
                      </span>{" "}
                    </p>

                    <hr />

                    <Link
                      to={{
                        pathname: `/customize/${params.uuid}/${params.slug}`,
                        search: `?key=${props.renderScript.path}`,
                      }}
                    >
                      <p className="text-[#69747e] text-sm pt-2 cursor-pointer">
                        <span className="font-medium text-[#25282b] text-sm hover:text-primary">
                          Customization
                        </span>
                      </p>
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
