import React, { useEffect, useRef, useState } from "react";
import { Switch } from "antd";
import ClipboardJS from "clipboard";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Dropdown, Flex } from "antd";
import { Tooltip } from "antd";
import { MutipleInput } from "./MutipleInput";
import { TableComponent } from "./TableComponent";

import axiosClient from "../../axios-client";

export const PublishPopup = (props) => {
  const navigate = useNavigate();
  const textToCopyRef = useRef(null);
  const buttonRef = useRef(null);
  let clipboard = null;
  const params = useParams();

  const validateEmail = props.validateEmail;
  const handleSendClick = props.handleSendClick;
  const sendLoading = props.sendLoading;
  const setEmaiState = props.setEmaiState;
  const emailState = props.emailState;
  const manageState = props.manageState;
  const setManage = props.setManage;
  const handleUserSearch = props.handleUserSearch;
  const setAccessUsers = props.setAccessUsers;
  const accessUsers = props.accessUsers;
  const handleRemove = props.handleRemove;
  const handleCheckboxChange = props.handleCheckboxChange;
  const handleMultipleUserDelete = props.handleMultipleUserDelete;
  const selectedIds = props.selectedIds;

  
  let duration = 2000;
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
    });
  };

  const showToastErrorMessage = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
    });
  };

  useEffect(() => {

    clipboard = new ClipboardJS(buttonRef.current, {
      text: () => textToCopyRef.current.innerText,
    });
    clipboard.on("success", (e) => {
      e.clearSelection(); // Deselect the text
      showToastMessage("Link Copied !");
    });

    return () => {
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, [props.renderScript]);

  const handleNavigates = () => {
    navigate(`/dashboard/${params.uuid}/s/${params.slug}`);
  };

  return (
    <div>
      <div className="bg-[#a3a2e9] opacity-[0.5] w-screen h-screen absolute top-0 left-0  z-10"></div>
      <div className=" flex items-center justify-center h-screen w-screen absolute top-2/4 left-2/4 transform -translate-x-1/2 -translate-y-1/2 z-20 ">
        <div className="bg-white h-[550px] w-[900px]  rounded-lg -z-10 ">
          <div className="flex">
            <div className="w-[280px] mt-8">
              <div className="w-[200px] m-auto space-y-2">
                <div
                  className={`hover:bg-[#e5e8f1] p-2 cursor-pointer rounded ${
                    manageState == "publish" && "bg-[#e5e8f1]"
                  }`}
                  onClick={() => setManage("publish")}
                >
                  <p>Publish to the web</p>
                </div>
                <div
                  className={`hover:bg-[#e5e8f1] p-2 cursor-pointer rounded ${
                    manageState == "manage" && "bg-[#e5e8f1]"
                  }`}
                  onClick={() => setManage("manage")}
                >
                  <p>Manage Publish</p>
                </div>
              </div>
            </div>
            <div className="bg-[#e5e8f1] w-[850px] h-[550px] rounded-r-lg max-h-[550px] overflow-auto">
              <div>
                <i
                  className="fa-solid fa-xmark text-[#8f97aa] pt-2 float-right text-2xl cursor-pointer mr-5"
                  onClick={handleNavigates}
                ></i>
              </div>
              {manageState == "publish" && (
                <div className="w-[620px] m-auto mt-9 space-y-2">
                  <p className="text-2xl font-semibold text-textPrimary">
                    Publish to the web
                  </p>
                  <p className="text-lg text-[#394863]">
                    Share your content online with a customizable layout.
                  </p>
                  <div className="box-border border-[#c5ccd8] h-28 w-full border-[1px] rounded bg-white">
                    <div className="flex w-[530px] m-auto space-x-5 mt-3.5">
                      <div>
                        <div className="rounded-full h-8 w-8 bg-primary mt-5">
                          <i className="fa-solid fa-globe text-lg pl-[7px] pt-[2px] text-white"></i>
                        </div>
                      </div>
                      <div className="flex space-x-16 ">
                        <div>
                          <p className="text-textPrimary text-xl ">
                            Publish this section to the web
                          </p>
                          <p className="text-textPrimary text-[14px] w-[350px] m-auto pt-1">
                            With an unique configurable URL, indexable by search
                            engines.
                          </p>
                        </div>
                        <div className="pt-5">
                          <Switch
                            checked={props.publishedData.is_published}
                            onChange={props.onChange}
                            className="bg-gray-400"
                            loading={props.switchLoading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {props.publishedData.is_published && (
                    <div className="box-border border-[#c5ccd8] py-3  w-full border-[1px] rounded bg-white">
                      <div className="w-[590px] m-auto">
                        <div className="flex justify-between  items-center">
                          <p className="text-lg">Your Published Link</p>
                          <div className="box-border border-[#c5ccd8] h-10 w-28 border-[1px] rounded-lg bg-primary flex space-x-2">
                            <a
                              href={`${props.publishedData?.path}`}
                              target="blank"
                            >
                              <p>
                                <i className="fa-solid fa-up-right-from-square pl-2 pt-2.5 text-white "></i>
                              </p>
                            </a>
                            <a
                              href={`${props.publishedData?.path}`}
                              target="blank"
                            >
                              <p className="pt-1.5  text-white ">Visit Site</p>
                            </a>
                          </div>
                        </div>
                        <div className="box-border border-[#c5ccd8] w-full border-[1px] py-1.5 rounded bg-sky-100 mt-4 ">
                          <div className="flex justify-between px-1 items-center ">
                            <p
                              ref={textToCopyRef}
                              className="text-[14px] text-textPrimary"
                            >
                              {`${props.publishedData?.path}`}
                            </p>
                            <button
                              className="bg-blue-500 ml-3 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
                              ref={buttonRef}
                              data-clipboard-text="Copy Text"
                            >
                              Copy
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {props.publishedData?.is_published && (
                    <div className="py-3 w-full border-[1px] rounded bg-white">
                      <div className="w-[590px] m-auto">
                        <div className="flex justify-between  items-center">
                          <p className="text-lg">Section URL</p>
                        </div>
                        <div className="flex justify-between items-center mt-2 ">
                          <div
                            className={` ${props.error && "border-red-400"}`}
                          >
                            <div className="flex">
                              <span className="inline-flex items-center pl-1 text-[12px] text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                {`${
                                  props.publishedData.path
                                    .split("/")
                                    .slice(0, 4)
                                    .join("/") + "/"
                                }`}
                              </span>
                              <input
                                type="text"
                                onChange={(e) => {
                                  props.setChangeUrlPath(e.target.value);
                                  {
                                    props.changeUrlPath
                                      ? props.setError(false)
                                      : props.setError(true);
                                  }
                                }}
                                value={props.changeUrlPath}
                                className={`${
                                  props.error == "required"
                                    ? "border-red-400 focus:outline-red-400"
                                    : ""
                                }rounded-none rounded-e-lg bg-gray-50 border text-gray-900  block flex-1 min-w-0 w-full text-[14px] font-medium border-gray-300 py-2.5 focus:outline-gray-400   dark:text-white  
                                `}
                              />
                            </div>
                          </div>

                          <button
                            className="bg-blue-500 ml-2.5 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={props.UrlChangeSave}
                          >
                            Save
                          </button>
                        </div>
                        <div className="text-sm text-primary mt-1.5">
                          <i className=" fa-solid fa-circle-info cursor-pointer text-sm text-[#a3aec2] "></i>{" "}
                          Old URLs will be redirected automatically.
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {manageState == "manage" && (
                <div className="w-[620px] m-auto mt-9 space-y-2">
                  <p className="text-2xl font-semibold text-textPrimary">
                    Manage publish
                  </p>
                  <p className="text-[16px] text-[#394863]">
                    Share your content online, whether public or private, with a
                    publication platform of your choice.
                  </p>
                  <div className="box-border border-[#c5ccd8]  w-full border-[1px]  py-4 rounded bg-white">
                    <div className="flex w-[580px] items-center m-auto space-x-5 ">
                      <div>
                        <div className="rounded-full h-8 w-8 bg-primary flex items-center justify-center">
                          <i className="fa-solid fa-lock text-lg text-white"></i>
                        </div>
                      </div>
                      <div className="flex space-x-16 ">
                        <div>
                          <p className="text-textPrimary text-lg font-inter">
                            Publish this section on the web, choosing between
                            private or public access
                          </p>
                        </div>
                        <div className="pt-5">
                          <Tooltip
                            title={
                              props.publishedData?.configurations[0]
                                ?.access_modes
                                ? "Enable to public"
                                : "Enable to private"
                            }
                          >
                            <Switch
                              checked={
                                props.publishedData?.configurations.length >
                                  0 &&
                                props.publishedData?.configurations[0]
                                  .access_modes
                              }
                              onChange={props.onAccessChanges}
                              // {console.log(props.publishedData.configurations[0].access_modes)}

                              className="bg-gray-400"
                              // loading={props.switchLoading}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  {console.log(emailState)}
                  {props.publishedData?.configurations[0]?.access_modes ? (
                    <>
                      <div>
                        <p className="font-medium">Invite Users Email</p>
                      </div>
                      <div className="flex items-center space-x-5">
                        <input
                          type="email"
                          id="helper-text"
                          aria-describedby="helper-text-explanation"
                          className="bg-gray-50 w-[35rem] border  border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-blue-500 block  p-2.5 "
                          placeholder="name@gmail.com"
                          value={emailState}
                          onChange={(e) => validateEmail(e)}
                        />

                        <button
                          type="button"
                          className={`flex   text-white bg-blue-700 hover:bg-blue-800 focus:ring-4  font-medium rounded-lg text-sm px-6 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ${
                            props.error
                              ? "focus:outline-red-600"
                              : "focus:ring-blue-300"
                          }`}
                          onClick={handleSendClick}
                          disabled={props.error ? true : false}
                        >
                          {sendLoading && (
                            <div role="status" className="pr-3">
                              <svg
                                aria-hidden="true"
                                className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-500"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          Send
                        </button>
                      </div>
                      {props.error && (
                        <span className="text-red-600">{props.error}</span>
                      )}
                      <div className="flex justify-between items-center">
                        <div className="relative">
                          <i className="fa-solid fa-magnifying-glass absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-400"></i>
                          <input
                            type="text"
                            className="search-input text-gray-700"
                            placeholder="Search"
                            onChange={(e) => handleUserSearch(e)}
                          />
                        </div>
                        <div>
                          {selectedIds?.length > 0 && (
                            <button
                              type="button"
                              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-1.5  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                              onClick={handleMultipleUserDelete}
                            >
                              Delete All
                            </button>
                          )}
                        </div>
                      </div>

                      {/* selectedIds */}

                      <div>
                        <TableComponent
                          accessUsers={accessUsers}
                          setAccessUsers={setAccessUsers}
                          handleRemove={handleRemove}
                          handleCheckboxChange={handleCheckboxChange}
                          selectedIds={selectedIds}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
