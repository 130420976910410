import React from "react";
import { Link } from "react-router-dom";
import errorImg from "../assets/images/Maintance.png";

export default function Error() {
  return (
    <div className="flex justify-center">
        <p className="text-3xl ">404 Not Found</p>
    </div>
  );
}
