import React from 'react'
import { BatchComponent } from '../components/BatchComponent'

export const Batch = () => {
  return (
    <>
        <BatchComponent />
    </>
  )
}
