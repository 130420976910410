import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import List from "@editorjs/list";
import Table from "@editorjs/table";
import Header from "@editorjs/header";
import InlineCode from "@editorjs/inline-code";
import Underline from "@editorjs/underline";
import Marker from "@editorjs/marker";
import Checklist from "@editorjs/checklist";
import Quote from "@editorjs/quote";
import Embed from "@editorjs/embed";
import ImageTool from "@editorjs/image";
import BreakLine from "editorjs-break-line";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../../axios-client";
import { PageTree } from "../commonComponents/PageTree";
import { Search } from "./Search";
import AttachesTool from "@editorjs/attaches";
import HashLoader from "react-spinners/HashLoader";
import { formatDistanceToNow, isValid } from "date-fns";
import "animate.css/animate.min.css";
import { ToastContainer, toast } from "react-toastify";

export const PreviewUrlPage = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const detailRef = useRef(null);
  const iconRef = useRef(null);

  const [script, setScript] = useState(null);
  const [page, setPages] = useState([]);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState(false);
  const [onThisPage, setOnThisPage] = useState([]);
  const [pageId, setpageId] = useState([]);
  const [customizeColor, setCustomizeColor] = useState(null);

  //style State
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [serachPopup, setsearchPopup] = useState(false);
  const [searchPageData, setSearchPageData] = useState(null);

  const [parentOpen, setParentOpen] = useState(null);
  const [url, setUrl] = useState(null);
  const [loadPage, setLoadPage] = useState({});

  const [queryParams, setQueryParams] = useState({});

  const searchInpRef = useRef();
  const createdAt = script?.createdAt;
  let formattedTime = "Invalid date";

  if (createdAt && isValid(new Date(createdAt))) {
    formattedTime = formatDistanceToNow(new Date(createdAt), {
      addSuffix: true,
    });
  }
  const updatedAt = script?.updatedAt;
  let formattedTimes = "Invalid date";

  if (createdAt && isValid(new Date(updatedAt))) {
    formattedTimes = formatDistanceToNow(new Date(updatedAt), {
      addSuffix: true,
    });
  }

  let duration = 2000;
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
    });
  };

  let queryPath = `${process.env.REACT_APP_BASE_URL}/${params.uuid}/${params.slug}/${params["*"]}`;
  let paramsObject = {};

  useEffect(() => {
    const url = new URL(window.location.href);
    const para = new URLSearchParams(url.search);
    console.log(para);

    para.forEach((value, key) => {
      paramsObject[key] =
        value == null || key === "font_family" ? value : `#${value}`;
    });

    setCustomizeColor(paramsObject);
    console.log(paramsObject);

    let path = `${process.env.REACT_APP_BASE_URL}/${params.uuid}/${params.slug}`;

    scriptRender(path);

    const updateScreenHeight = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Attach the event listener for window resize
    window.addEventListener("resize", updateScreenHeight);

    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const scriptRender = (path) => {
    setLoading(true);

    axiosClient
      .get(`/api/public/documents?path=${path}`)
      .then((res) => {
        if (!res.data.script.is_published) {
          navigate("/underMaintenance");
        }
        setLoading(false);
        setPages(res.data.hierarchy);
        setScript(res.data.script);
        if (paramsObject.header_colorCode == "#null") {
          setCustomizeColor(res.data.customized_script);
        }
        let url = new URL(res.data.hierarchy[0].path);
        let paths = url.pathname;
        if (!params["*"]) {
          let para = `${process.env.REACT_APP_BASE_URL}${paths}`;
          pageRender(para);
        } else {
          pageRender(queryPath);
        }
      })
      .catch((err) => {
        const response = err.response;

        // if (response && response?.status === 302) {
        //   navigate(response.data.path);
        //   let url = new URL(response.data.path);
        //   let paths = url.pathname;
        //   navigate(paths);
        // } else {
        console.log("Error:", err);
        setLoading(false);
        // }
        // setLoading(false);
      });
  };

  const pageRender = (para) => {
    setLoading(false);

    setOnThisPage(null);

    setIsLoading(true);
    axiosClient
      .get(`/api/public/pages?path=${para}`)
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          setLoadPage(res.data.publicUrl);
          setEditorValue(res.data.publicUrl.content);
          setParentOpen(res.data.parentPages);

          const headers = res.data.publicUrl.content.blocks.filter(
            (block) => block.type === "header"
          );
          const headerValuesWithId = headers.map((header) => ({
            id: header.id,
            text: header.data.text.replace(/<[^>]*>/g, ""),
          }));
          setOnThisPage(headerValuesWithId);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        const response = err.response;

        if (response && response?.status === 404) {
          navigate("/underMaintenance");
        }
        if (response && response?.status === 302) {
          showToastMessage(response.data.msg);

          let url = new URL(
            `${process.env.REACT_APP_BASE_URL}/${params.uuid}/${params.slug}`
          );
          let paths = url.pathname;

          navigate(paths);
          params["*"] = "";
          let reRender = `${process.env.REACT_APP_BASE_URL}/${params.uuid}/${params.slug}`;

          scriptRender(reRender);
        }

        console.log("Error:", err);
      });
  };

  const ejInstance = useRef();

  const [editorValue, setEditorValue] = useState(null);

  const contentPage = (e) => {
    // Assuming the data-set attribute contains the entire URL
    let url = new URL(e.target.dataset.set);

    // Extract the path from the URL
    let targetPath = url.pathname;
    // Navigate to the extracted path
    navigate(targetPath);

    if (targetPath) {
      let para = `${process.env.REACT_APP_BASE_URL}/${targetPath}`;

      pageRender(para);
    }
  };

  const containerStyles = {
    fontFamily: customizeColor?.font_family
      ? customizeColor?.font_family
      : "Inter",
    fontSize: "17px",
  };

  const initEditor = () => {
    const editor = new EditorJS({
      holder: "editorjs",
      onReady: () => {
        if (editor) {
          ejInstance.current = editor;
        }
      },
      readOnly: true,
      autofocus: false,
      placeholder: "Let`s write an awesome story!",
      data: editorValue,
      onChange: async () => {
        let content = await editor.saver.save();
      },
      tools: {
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },
        header: Header,
        image: {
          class: ImageTool,
        },
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        Marker: {
          class: Marker,
          shortcut: "CMD+SHIFT+M",
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+O",
          config: {
            quotePlaceholder: "Enter a quote",
            captionPlaceholder: "Quote's author",
          },
        },
        embed: {
          class: Embed,
          inlineToolbar: true,
        },
        breakLine: {
          class: BreakLine,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+ENTER",
        },
        attaches: {
          class: AttachesTool,
        },
        underline: Underline,
      },
    });
  };

  // This will run only once
  useEffect(() => {
    const editorElement = document.getElementById("editorjs");

    if (ejInstance.current === null && editorValue) {
      initEditor();
    }

    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, [editorValue]);

  const searchEvent = async (e) => {
    let searchPath = `${process.env.REACT_APP_BASE_URL}/${params.uuid}/${params.slug}`;
    let value = e.target.value;

    await axiosClient
      .get(`/api/dashboard/${searchPath}/pageSearch/items?q=${value}`)
      .then((res) => {
        if (res.data.length > 0) {
          setSearchPageData(res.data);
        } else {
          setSearchPageData(null);
        }
      })
      .catch((err) => {
        const response = err.response;
        if (response && response?.status === 404) {
          setSearchPageData(null);
        } else {
          console.error("Error:", response?.status);
        }
      });
  };

  const closeOnOutsideClick = (e) => {
    if (
      detail &&
      detailRef.current &&
      !detailRef.current.contains(e.target) &&
      e.target !== iconRef.current
    ) {
      setDetail(false);
    }
  };

  window.addEventListener("click", closeOnOutsideClick);

  // Cleanup function to remove event listener when component unmounts
  const cleanup = () => {
    window.removeEventListener("click", closeOnOutsideClick);
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: `100vh`,
          }}
        >
          <div role="status" className="">
            <svg
              aria-hidden="true"
              className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="">
          <div
            className={`flex justify-between items-center py-[20px] px-[30px] shadow-sm`}
            style={{
              backgroundColor: customizeColor?.header_colorCode || "white",
            }}
          >
            <div className="flex items-center space-x-2">
              {script?.logo ? (
                <img src={script?.logo} alt="" className="w-8 " />
              ) : (
                <i className="fa-regular text-slate-600 fa-image text-2xl cursor-pointer pr-1"></i>
              )}

              <p
                className="font-bold text-2xl phone:text-xl font-inter"
                style={{
                  color: customizeColor?.font_colorCode || "white",
                  fontFamily: customizeColor?.font_family,
                }}
              >
                {script && script.title}
              </p>
            </div>
            <div className="flex justify-between space-x-8">
              <div className="relative phone:w-[150px] w-[170px]">
                <input
                  type="search"
                  id="search-dropdown"
                  className="block p-[10px] phone:p-[5px] w-[170px] phone:w-[150px] z-20 text-sm text-gray-900 bg-white rounded-lg focus:outline-slate-300  placeholder-gray-400 dark:text-white cursor-pointer border-[1px] "
                  placeholder="Search here"
                  autoComplete="off"
                  required
                  readOnly
                  onClick={() => setsearchPopup((prevState) => !prevState)}
                  ref={searchInpRef}
                />
                <button
                  type="submit"
                  className="absolute top-0 right-0 p-2 text-sm font-medium h-full text-white bg-[#99a5b8] rounded-r-lg  focus:outline-none "
                >
                  <i className="fa-solid fa-magnifying-glass "></i>
                  <span className="sr-only">Search</span>
                </button>
              </div>
              <div className="flex items-center ">
                <div className="relative group ">
                  <i
                    className=" fa-solid fa-circle-info cursor-pointer text-2xl text-[#a3aec2] "
                    title="Info"
                    ref={iconRef}
                    onClick={() => setDetail((prevState) => !prevState)}
                  ></i>
                  {detail && (
                    <div
                      className="bg-white w-64 py-5 px-4  absolute top-8.5 border-[1px] right-[-10px] z-30 shadow-md rounded-lg "
                      ref={detailRef}
                    >
                      <p className="text-[#69747e] text-sm pt-1">
                        <span className="font-medium text-[#25282b] text-sm ">
                          Created At :
                        </span>{" "}
                        {formattedTime}
                      </p>
                      <p className="text-[#69747e] text-sm pt-1">
                        <span className="font-medium text-[#25282b] text-sm">
                          Last Modified At :
                        </span>{" "}
                        {formattedTimes}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr className="" />
          <div
            className="flex"
            style={{
              height: `calc(100vh - 85px)`,
            }}
          >
            <div
              className=" overflow-x-hidden border-r-[1px]"
              style={{
                maxHeight: `calc(${screenHeight}px - 85px)`,
                backgroundColor: customizeColor?.side_bar_colorCode || "white",
              }}
            >
              <div className="w-[250px]  phone:w-[150px]  pr-[10px] pl-[24px] pt-[20px]">
                {page.map((topLevelPage, index) => (
                  <div
                    key={topLevelPage.uuid}
                    id={topLevelPage.page_id}
                    className=""
                  >
                    <PageTree
                      node={topLevelPage}
                      hasSibling={index < page.length - 1}
                      hasParent={false}
                      contentPage={contentPage}
                      parentOpen={parentOpen}
                      cutomizeTextColor={customizeColor?.font_colorCode}
                      contrastColor={queryParams?.contrastColor}
                      cutomizeFontFamily={customizeColor?.font_family}
                    />
                  </div>
                ))}
              </div>
            </div>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: `calc(100vh - 85px)`,
                  width: "60%",
                }}
              >
                <div role="status" className="">
                  <svg
                    aria-hidden="true"
                    className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-500"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className=" overflow-auto pt-10 phone:pl-[6px] px-14  "
                style={{
                  width:
                    screenWidth > "425"
                      ? "calc(100% - 250px)"
                      : "calc(100% - 150px)",
                  maxHeight: `calc(${screenHeight}px - 85px)`,
                }}
              >
                <div className=" flex justify-between">
                  <div>
                    <div className="flex items-center space-x-2">
                      <img
                        className="cursor-pointer w-[25px]"
                        src={
                          loadPage?.emoji
                            ? loadPage?.emoji
                            : `http://icons.getbootstrap.com/assets/icons/emoji-smile.svg`
                        }
                      />
                      <h1
                        className="text-3xl font-bold phone:text-[18px] font-inter  phone:w-[190px]"
                        style={{ fontFamily: customizeColor?.font_family }}
                      >
                        {page?.length == 0
                          ? "Page Name"
                          : loadPage?.title && loadPage?.title.split("-")[0]}
                      </h1>
                    </div>
                    <h4
                      className="text-[20px] my-3 ml-[32px]  font-normal font-inter phone:text-[16px] phone:w-[170px] "
                      style={{ fontFamily: customizeColor?.font_family }}
                    >
                      {page?.length == 0
                        ? "Page description"
                        : loadPage?.description}
                    </h4>
                  </div>
                  {onThisPage?.length > 0 && (
                    <div className="float-right fixed right-4 max-w-[220px] w-[100%]">
                      <p className="font-semibold text-gray-500 text-lg font-inter">
                        On This Page
                      </p>
                      {onThisPage &&
                        onThisPage.map((page) => (
                          <div className="mt-2 cursor-pointer" key={page.id}>
                            <a
                              href={"#" + page.id}
                              className={`text-[#495057] ${
                                window.location.hash == "#" + page.id &&
                                "text-primary font-medium"
                              } text-sm`}
                            >
                              {page.text}
                            </a>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <div
                  id="editorjs"
                  className="mr-64 phone:pl-[30px] font-inter "
                  style={containerStyles}
                ></div>
              </div>
            )}
          </div>
          {console.log(customizeColor)}
          {serachPopup && (
            <Search
              searchInpRef={searchInpRef}
              setsearchPopup={setsearchPopup}
              searchEvent={searchEvent}
              searchPageData={searchPageData}
              setSearchPageData={setSearchPageData}
              serachPopup={serachPopup}
            />
          )}
        </div>
      )}
      <ToastContainer />

      <></>
    </>
  );
};
