import React, { useEffect, useRef, useState } from "react";
import axiosClient from "../../axios-client";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import EditHeader from "../../common/commonLayouts/EditHeader";
import EditPage from "../../common/commonLayouts/EditPage";
import { ToastContainer, toast } from "react-toastify";
import { useMyContext } from "../../context/AppContext";
import { UrlCopyPopup } from "../../common/commonComponents/UrlCopyPopup";
import { PageDeleteConfirmation } from "../../common/commonComponents/PageDeleteConfirmation";
import { v4 as uuidv4 } from "uuid";
export const ScriptComponents = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    getScript,
    script,
    getScripts,
    setLoading,
    role,
    hasChanges,
    setHasChanges,
    userDetail,
    getAllActiveUsers,
    activeTeamMembers,
  } = useMyContext();

  //hooks

  const [inputValue, setInputValue] = useState("");

  const [editorContent, setEditorContent] = useState([]);

  const [description, setDescription] = useState("");

  const [pageId, setPageId] = useState(null);

  const [treeNode, setTreeNode] = useState([]);

  const [renderScript, setRenderScript] = useState({});
  const [teamUuid, setTeamUuid] = useState([]);

  const [pageContent, setPageContent] = useState(null);

  const [particularTitle, setParticularTitle] = useState("");

  const [hoverPageId, setHoverPageId] = useState(null);

  const [particularPageId, setParticularPageId] = useState(null);

  const [editorValue, setEditorValue] = useState([]);

  const [shareState, setShareState] = useState(false);

  const [publish, setPublish] = useState([]);

  const [scriptError, setScriptError] = useState(null);

  const [selectDiv, setselectDiv] = useState([]);

  const [state, setState] = useState(true);

  const [parentOpen, setParentOpen] = useState(null);

  const [popUp, setPopUp] = useState(null);

  const [isLoading, setIsLoading] = useState(null);

  const [switchLoading, setSwitchLoading] = useState(false);

  const [urlCopyPopup, setUrlCopyPopup] = useState(false);

  const [divId, setDivId] = useState("");

  //page count

  const [maintainPageCount, setMaintainPageCount] = useState(null);

  const [instance, setInstance] = useState("");

  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [revisiedHistory, setRevisiedHistory] = useState([]);

  const [pageDeleteConfirmation, setPageDeleteConfirmation] = useState(null);

  const [selectedText, setSelectedText] = useState(null);
  const [commentIconPosition, setCommentIconPosition] = useState({
    x: 0,
    y: 0,
  });

  const [commentPopup, setCommentPopup] = useState(false);

  const [count, setCount] = useState(null);

  const duration = 2000;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageIds = queryParams.get("pageId");

  const showToastSaveMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
    });
  };

  const showToastErrorMessage = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
    });
  };

  useEffect(() => {
    if (pageIds) {
      getParentOpen();
      getParticularPage();
      getParticularScript();
      getScripts();
      getAllActiveUsers();
    } else {
      getParticularScript();
      getScripts();
      getParticularOpenScript();
    }
  }, [pageIds]);

  //Api

  const getParticularPage = async () => {
    setIsLoading(true);
    await axiosClient
      .get(`/api/pages/getPage/${pageIds}`)
      .then((res) => {
        setRevisiedHistory(res.data.revised_history);

        setParticularTitle(res.data.pages.title);
        setDescription(res.data.pages.description);
        setInputStr(res.data.pages.emoji);
        setEditorValue(res.data.pages.content ? res.data.pages.content : []);
        setEditorContent(res.data.pages.content);
        getSelectComments();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getParticularOpenScript = async () => {
    let script_uuid = params.slug;

    await axiosClient
      .get(`/api/dashboard/getScriptAndPage/${script_uuid}`)
      .then((res) => {
        navigate(
          `/dashboard/${params.uuid}/s/${params.slug}/?pageId=${res.data.hierarchy[0].uuid}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getParentOpen = async () => {
    await axiosClient
      .get(`/api/dashboard/getOpenParent/${pageIds}`)
      .then((res) => {
        setParentOpen(res.data.parentPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getParticularScript = async () => {
    setLoading(true);
    let script_uuid = params.slug;
    await axiosClient
      .get(`/api/dashboard/getScriptAndPage/${script_uuid}`)
      .then((res) => {
        if (res.status == 200) {
          if (
            res.data.getScriptAndPages.is_published &&
            res.data.getScriptAndPages.status == "edit-request"
          ) {
            // navigate(`/dashboard/${params.uuid}/edit/${params.slug}`);
            console.log(params);
          }
          if (
            res.data.getScriptAndPages.is_published &&
            res.data.getScriptAndPages.status == "merge-request"
          ) {
            navigate(`/dashboard/${params.uuid}/changes/${params.slug}`);
          }
          setInputValue(res.data.getScriptAndPages.title);
          setPageContent(res.data.hierarchy[0]);
          setTreeNode(res.data.hierarchy);
          setRenderScript(res.data.getScriptAndPages);
          setPublish(res.data.getScriptAndPages);
          setMaintainPageCount(res.data.pageCount);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //Editor functionality

  const handleSave = () => {
    setLoading(true);

    const postData = {
      id: pageIds,
      script_uuid: params.slug,
      title: particularTitle ? particularTitle : "Page Name",
      description: description ? description : "Page Description",
      content: editorContent,
      emoji: inputStr,
    };

    axiosClient
      .post("/api/pages/updatePageData", postData)
      .then((res) => {
        getParticularScript();
        getParticularPage();
        showToastSaveMessage(res.data.msg);
        setLoading(false);
        setHasChanges(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSaveAndPublish = () => {
    setLoading(true);

    const postData = {
      id: pageIds,
      script_uuid: params.slug,
      title: particularTitle ? particularTitle : "Page Name",
      description: description ? description : "Page Description",
      content: editorContent,
      emoji: inputStr,
    };
    axiosClient
      .post("/api/pages/updatePageData", postData)
      .then((res) => {
        getParticularScript();
        getParticularPage();
        setLoading(false);
        setHasChanges(false);

        contentPublish(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const contentPublish = () => {
    if (!hasChanges) {
      setLoading(true);

      axiosClient
        .get(`/api/public/scripts/${params.slug}/${true}`)

        .then((res) => {
          setRenderScript(res.data.publicUrl);
          setUrlCopyPopup(true);
          setTeamUuid(params.uuid);
          setLoading(false);
          {
            res.data.publicUrl.is_published
              ? showToastSaveMessage("Save and Published successsfully")
              : showToastErrorMessage(res.data.msg);
          }
        })

        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      showToastErrorMessage("Please save the changes");
    }
  };

  const addPage = async () => {
    setLoading(true);
    await axiosClient
      .post(`/api/pages/addPageData/${params.slug}`)
      .then((res) => {
        getParticularScript();
        setLoading(false);
        showToastSaveMessage(res.data.msg);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const addChildPage = (uuid) => {
    setLoading(true);
    let page_uuid = uuid;
    axiosClient
      .post(`/api/pages/addChildPage/${params.slug}/${page_uuid}`)
      .then((res) => {
        getParticularScript();
        handleParaSave(pageIds, res.data.Pages.uuid);

        showToastSaveMessage(res.data.msg);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChange = async (event) => {
    const inputValue = event;

    setInputValue(inputValue); // Update the state with the current value

    let paraId = params.slug;
    const encodedInputValue = inputValue;
    let payload = {
      inputValue: encodedInputValue,
      queryParameter: paraId,
      teamParameter: params.uuid,
    };
    await axiosClient
      .post("/api/scripts/addScriptTitle", payload)
      .then((res) => {
        getScripts();
        getScript();
      })
      .catch((err) => {
        const response = err.response;
        if (response && response?.status === 403) {
          showToastErrorMessage(response.data.errorMsg);
        } else {
          console.error("Error:", response?.status);
        }
      });
  };

  const handleParaSave = (uuid, targetId) => {
    setLoading(true);

    if (hasChanges) {
      const postData = {
        id: uuid,
        script_uuid: params.slug,
        title: particularTitle ? particularTitle : "Page Name",
        description: description ? description : "Page Description",
        content: editorContent,
        emoji: inputStr,
      };

      axiosClient
        .post("/api/pages/updatePageData", postData)
        .then((res) => {
          showToastSaveMessage(res.data.msg);
          setLoading(false);
          setHasChanges(false);

          navigate(
            `/dashboard/${params.uuid}/s/${params.slug}/?pageId=${targetId}`
          );
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      navigate(
        `/dashboard/${params.uuid}/s/${params.slug}/?pageId=${targetId}`
      );
    }
  };

  const contentPage = async (e) => {
    setHasChanges(false);
    setPageId(e.target.id);
    let targetId = e.target.id;
    handleParaSave(pageIds, targetId);
  };

  const handleScriptMouseEnter = (e) => {
    setHoverPageId(e.target.id);
  };

  const handleScriptMouseLeave = (e) => {
    setHoverPageId(null);
  };

  const handleMore = (e) => {
    setParticularPageId(e.target.id);
    addChildPage(e.target.id);
    setPopUp(null);
    setHoverPageId(null);

    if (localStorage.getItem("mainId")) {
      localStorage.removeItem("mainId");
    }
  };

  const handlePageDelete = async (e) => {
    let targetId = e.target.id;
    setLoading(true);

    if (targetId) {
      await axiosClient
        .delete(`/api/pages/permanentDeletePage/${targetId}`)
        .then((res) => {
          setPageDeleteConfirmation(null);
          if (res.data.page.page_uuid != null) {
            navigate(
              `/dashboard/${params.uuid}/s/${params.slug}/?pageId=${res.data.page.page_uuid}`
            );
            setLoading(false);
            showToastErrorMessage(res.data.Success);
          } else {
            navigate(`/dashboard/${params.uuid}/s/${params.slug}/`);
            setLoading(false);
            showToastErrorMessage(res.data.Success);
          }
        })
        .catch((err) => {
          const response = err.response;
          console.error("Error:", response?.status);
          setLoading(false);
        });
    }
    if (localStorage.getItem("mainId")) {
      localStorage.removeItem("mainId");
    }
  };

  const HandleShare = () => {
    setShareState(true);
  };

  const onChange = (checked) => {
    setSwitchLoading(true);
    axiosClient
      .get(`/api/public/scripts/${params.slug}/${checked}`)

      .then((res) => {
        setRenderScript(res.data.publicUrl);
        setTeamUuid(params.uuid);
        {
          res.data.publicUrl.is_published
            ? showToastSaveMessage(res.data.msg)
            : showToastErrorMessage(res.data.msg);
        }
        setSwitchLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setSwitchLoading(false);
      });
  };

  const handleDeletePage = () => {
    setPageDeleteConfirmation(null);
    if (localStorage.getItem("mainId")) {
      localStorage.removeItem("mainId");
    }
  };

  // let divElements = document.querySelectorAll(".ce-block");
  // divElements.forEach((div) => {
  //   const divId = div.dataset.id;
  //   div.setAttribute("id", divId);

  //   div.addEventListener("click", (e) => {
  //     setDivId(e.target.id);
  //   });
  // });

  // let list = document.querySelectorAll(".cdx-list__item");

  // list.forEach((listItem, index) => {
  //   const staticId = "listItem" + index; // You can customize the static id as needed
  //   listItem.setAttribute("id", staticId);
  //   listItem.addEventListener("click", (e) => {
  //     console.log(e.target.id);
  //     setDivId(e.target.id);
  //   });
  // });

  // const handleMouseUp = () => {
  //   const selection = window.getSelection();
  //   console.log(selection);
  //   // let one = document.querySelectorAll(".ce-block--focused");
  //   // console.log(one);
  //   // setselectDiv(one);
  //   if (selection && selection.toString()) {
  //     setSelectedText(selection.toString());
  //     const range = selection.getRangeAt(0);
  //     const rect = range.getBoundingClientRect();
  //     const iconHeight = 5; // Adjust this value as needed
  //     setCommentIconPosition({ x: 1210, y: rect.top - iconHeight });
  //   } else {
  //     setSelectedText("");
  //     setCommentIconPosition({ x: 0, y: 0 });
  //   }
  // };

  const handleSelectComment = (value) => {
    setCommentPopup(value);
  };

  const [handleComments, setHandleComments] = useState(null);

  const [selectCommentValue, setSelectCommentValue] = useState("");

  const [selectEditCommentValue, setSelectEditCommentValue] = useState("");

  const [allSelectComments, setAllSelectComments] = useState([]);

  const [handleEditValue, setHandleEditValue] = useState(null);

  const [selectedOption, setSelectedOption] = useState([]);

  const [selectCommentLoading, setSelectedCommentLoading] = useState(false);
  const [selectChildCommentLoading, setSelectedChildCommentLoading] =
    useState(false);

  const handleParentComment = () => {
    setSelectedCommentLoading(true);
    if (selectedOption && selectedOption.id) {
      const uuid = selectedOption.id;
      console.log("Selected UUID:", uuid);
    }

    // Prepare the data to send in the API call
    const data = {
      selectCommentValue,
      selectedOption,
    };

    console.log(data);
    let payLoad = {
      id: divId,
      page_uuid: pageIds,
      content: selectedText ? selectedText : null,
      comments: data,
      comment_uuid: null,
    };
    // console.log(handleComments);
    axiosClient
      .post(`/api/comments/postLineComments`, payLoad)
      .then((res) => {
        getSelectComments();
        setHandleComments(null);
        setSelectCommentValue("");
        setSelectedOption([]);
        handleSave();
        setSelectedText(null);
        setSelectedCommentLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSelectedCommentLoading(false);
      });
  };

  const handleChildComment = (uuid, value) => {
    setSelectedChildCommentLoading(true);
    if (selectedOption && selectedOption.id) {
      const uuid = selectedOption.id;
      console.log("Selected UUID:", uuid);
    }

    // Prepare the data to send in the API call
    const data = {
      selectEditCommentValue,
      selectedOption,
    };

    console.log(data);
    let payLoad = {
      id: divId,
      page_uuid: pageIds,
      content: selectedText ? selectedText : value,
      comments: data,
      comment_uuid: uuid,
    };
    // console.log(handleComments);
    axiosClient
      .post(`/api/comments/postLineComments`, payLoad)
      .then((res) => {
        getSelectComments();
        setHandleComments(null);
        setSelectEditCommentValue("");
        setSelectedOption([]);
        // handleSave();
        setSelectedText(null);
        setSelectedChildCommentLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSelectedChildCommentLoading(false);
      });
  };

  const getSelectComments = () => {
    axiosClient
      .get(`/api/comments/getLineComments?page_uuid=${pageIds}`)
      .then((res) => {
        setAllSelectComments(res.data.hierarchy);
        setCount(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectedCommentDelete = (uuid) => {
    axiosClient
      .delete(`/api/comments/deleteLineComments?id=${uuid}`)
      .then((res) => {
        getSelectComments();
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const handleSelectedCommentResolve = (uuid) => {
    axiosClient
      .delete(`/api/comments/resolveLineComments?id=${uuid}`)
      .then((res) => {
        getSelectComments();
      })

      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <EditHeader
        clickPublish={handleSave}
        handleSaveAndPublish={contentPublish}
        changeEvent={handleChange}
        inputValue={inputValue}
        setInputValue={setInputValue}
        renderScript={renderScript}
        HandleShare={HandleShare}
        scriptError={scriptError}
        publish={publish}
        role={role}
        onChange={onChange}
        getParticularScript={getParticularScript}
      />

      <EditPage
        treeNode={treeNode}
        selectDiv={selectDiv}
        addPage={addPage}
        contentPage={contentPage}
        pageContent={pageContent}
        particularTitle={particularTitle}
        setParticularTitle={setParticularTitle}
        description={description}
        setDescription={setDescription}
        handleScriptMouseEnter={handleScriptMouseEnter}
        handleScriptMouseLeave={handleScriptMouseLeave}
        hoverPageId={hoverPageId}
        handleMore={handleMore}
        handleSave={handleSave}
        shareState={shareState}
        setShareState={setShareState}
        onChange={onChange}
        publish={publish}
        editorValue={editorValue}
        setEditorValue={setEditorValue}
        editorContent={editorContent}
        setEditorContent={setEditorContent}
        renderScript={renderScript}
        parentOpen={parentOpen}
        teamUuid={teamUuid}
        role={role}
        setHoverPageId={setHoverPageId}
        popUp={popUp}
        setPopUp={setPopUp}
        handlePageDelete={handlePageDelete}
        isLoading={isLoading}
        maintainPageCount={maintainPageCount}
        inputStr={inputStr}
        setInputStr={setInputStr}
        showPicker={showPicker}
        setShowPicker={setShowPicker}
        setPageDeleteConfirmation={setPageDeleteConfirmation}
        clickPublish={handleSave}
        revisiedHistory={revisiedHistory}
        handleSelectComment={handleSelectComment}
        setDivId={setDivId}
        divId={divId}
        commentIconPosition={commentIconPosition}
        selectedText={selectedText}
        setSelectedText={setSelectedText}
        commentPopup={commentPopup}
        setCommentPopup={setCommentPopup}
        handleComments={handleComments}
        setHandleComments={setHandleComments}
        handleParentComment={handleParentComment}
        allSelectComments={allSelectComments}
        handleSelectedCommentDelete={handleSelectedCommentDelete}
        count={count}
        handleSelectedCommentResolve={handleSelectedCommentResolve}
        setHandleEditValue={setHandleEditValue}
        getSelectComments={getSelectComments}
        userDetail={userDetail}
        selectCommentLoading={selectCommentLoading}
        activeTeamMembers={activeTeamMembers}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        selectCommentValue={selectCommentValue}
        setSelectCommentValue={setSelectCommentValue}
        selectEditCommentValue={selectEditCommentValue}
        setSelectEditCommentValue={setSelectEditCommentValue}
        handleChildComment={handleChildComment}
        selectChildCommentLoading={selectChildCommentLoading}
      />

      {urlCopyPopup && <UrlCopyPopup renderScript={renderScript} />}
      {pageDeleteConfirmation && (
        <PageDeleteConfirmation
          setPageDeleteConfirmation={setPageDeleteConfirmation}
          handleDeletePage={handleDeletePage}
          pageDeleteConfirmation={pageDeleteConfirmation}
          handlePageDelete={handlePageDelete}
        />
      )}
    </>
  );
};
