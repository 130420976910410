import React, { useEffect, useRef, useState } from "react";
import axiosClient from "../../axios-client";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import EditHeader from "../../common/commonLayouts/EditHeader";
import EditPage from "../../common/commonLayouts/EditPage";
import { ToastContainer, toast } from "react-toastify";
import { useMyContext } from "../../context/AppContext";
import { ViewHeader } from "../../common/commonLayouts/ViewHeader";
import { ViewPage } from "../../common/commonLayouts/ViewPage";
import HashLoader from "react-spinners/HashLoader";
import { PublishPopup } from "../../common/commonComponents/PublishPopup";

export const ScriptEditor = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    getScript,
    script,
    getScripts,
    role,
    setPublished,
    showToastMessage,
    userDetail,
    getAllActiveUsers,
    activeTeamMembers,
  } = useMyContext();

  //hooks

  const [inputValue, setInputValue] = useState("");

  const [editorContent, setEditorContent] = useState([]);

  const [description, setDescription] = useState("");

  const [pageId, setPageId] = useState(null);

  const [treeNode, setTreeNode] = useState([]);

  const [renderScript, setRenderScript] = useState({});
  const [teamUuid, setTeamUuid] = useState([]);

  const [pageContent, setPageContent] = useState(null);

  const [particularTitle, setParticularTitle] = useState("");

  const [hoverPageId, setHoverPageId] = useState(null);

  const [particularPageId, setParticularPageId] = useState(null);

  const [editorValue, setEditorValue] = useState([]);

  const [publish, setPublish] = useState([]);

  const [scriptError, setScriptError] = useState(null);
  const [error, setError] = useState(null);

  const [switchLoading, setSwitchLoading] = useState(false);

  const [loading, setLoading] = useState(false);
  const [parentOpen, setParentOpen] = useState(null);

  const [emoji, setEmoji] = useState("");

  const [publishPopup, setPublishPopup] = useState("");
  const [publishedData, setPublishedData] = useState(null);
  const [changeUrlPath, setChangeUrlPath] = useState(null);
  const [revisiedHistory, setRevisiedHistory] = useState([]);

  const [divId, setDivId] = useState("");

  //publish
  const [sendLoading, setSendLoading] = useState(false);

  const [manageState, setManage] = useState("publish");
  const [tags, setTags] = useState([]);
  const [emailState, setEmaiState] = useState("");
  const [accessUsers, setAccessUsers] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);

  const [selectedText, setSelectedText] = useState("");

  const [commentIconPosition, setCommentIconPosition] = useState({
    x: 0,
    y: 0,
  });

  const [commentPopup, setCommentPopup] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageIds = queryParams.get("pageId");

  useEffect(() => {
    setLoading(false);

    if (pageIds) {
      getParentOpen();
      getParticularPage();
      getParticularScript();
      getScripts();
      getAllActiveUsers();
    } else {
      getParticularScript();
      getScripts();
      getParticularOpenScript();
    }
  }, [params.slug, params, pageIds]);

  //Api

  const duration = 2000;

  const showToastSaveMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
    });
  };

  const showToastErrorMessage = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration,
      hideProgressBar: true,
      draggable: true,
      closeOnClick: true,
    });
  };

  const getParticularPage = async () => {
    setLoading(true);
    await axiosClient
      .get(`/api/pages/getPage/${pageIds}`)
      .then((res) => {
        setRevisiedHistory(res.data.revised_history);
        setParticularTitle(res.data.pages.title);
        setDescription(res.data.pages.description);
        setEmoji(res.data.pages.emoji);
        setEditorValue(res.data.pages.content);
        getSelectComments();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getParticularOpenScript = async () => {
    setLoading(true);

    let script_uuid = params.slug;

    await axiosClient
      .get(`/api/dashboard/getScriptAndPage/${script_uuid}`)
      .then((res) => {
        navigate(
          `/dashboard/${params.uuid}/edit/${params.slug}/?pageId=${res.data.hierarchy[0].uuid}`
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getParentOpen = async () => {
    setLoading(true);
    if (pageIds) {
      await axiosClient
        .get(`/api/dashboard/getOpenParent/${pageIds}`)
        .then((res) => {
          // setParentOpen(res.data.parentPages);
          // setLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          // setLoading(false);
        });
    }
  };

  const getParticularScript = async () => {
    setLoading(true);

    let script_uuid = params.slug;
    await axiosClient
      .get(`/api/dashboard/getScriptAndPage/${script_uuid}`)
      .then((res) => {
        if (res.status == 200) {
          setPublishedData(res.data.getScriptAndPages);
          setChangeUrlPath(res.data.getScriptAndPages.path.split("/")[4]);
          // if (
          //   res.data.getScriptAndPages.is_published &&
          //   res.data.getScriptAndPages.status == "merge-request"
          // ) {
          //   navigate(`/dashboard/${params.uuid}/changes/${params.slug}`);
          // }
          // if (!res.data.getScriptAndPages.is_published) {
          //   navigate(`/dashboard/${params.uuid}/s/${params.slug}`);
          // }
          setInputValue(res.data.getScriptAndPages.title);
          setPageContent(res.data.hierarchy[0]);
          setTreeNode(res.data.hierarchy);
          setRenderScript(res.data.getScriptAndPages);
          setPublish(res.data.getScriptAndPages);
          getAccessUsers();

          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const contentPage = async (e) => {
    setPageId(e.target.id);
    let pageId = e.target.id;
    navigate(`/dashboard/${params.uuid}/edit/${params.slug}/?pageId=${pageId}`);
  };

  const handleScriptMouseEnter = (e) => {
    setHoverPageId(e.target.id);
  };

  const handleScriptMouseLeave = (e) => {
    setHoverPageId(null);
  };

  const handleEdit = () => {
    setLoading(true);

    let payLoad = {
      script_uuid: params.slug,
      status: "merge-request",
    };
    axiosClient
      .put(`/api/scripts/updateStatus`, payLoad)
      .then((res) => {
        showToastMessage(res.data.msg);
        navigate(`/dashboard/${params.uuid}/changes/${params.slug}`);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onChange = (checked) => {
    setSwitchLoading(true);
    axiosClient
      .get(`/api/public/scripts/${params.slug}/${checked}`)

      .then((res) => {
        setPublishedData(res.data.publicUrl);
        setSwitchLoading(false);

        setTeamUuid(params.uuid);

        {
          res.data.publicUrl.is_published
            ? showToastSaveMessage(res.data.msg)
            : showToastErrorMessage(res.data.msg);
        }
      })

      .catch((err) => {
        setSwitchLoading(false);

        console.log(err);
      });
  };

  const onAccessChanges = (checked) => {
    setSwitchLoading(true);
    let payLoad = {
      script_uuid: params.slug,
      access: checked,
    };
    axiosClient
      .put(`/api/public/managePublicAndPrivate`, payLoad)

      .then((res) => {
        // setPublishedData(res.data.publicUrl);
        console.log(res.data);
        getParticularScript();
        setSwitchLoading(false);
        getAccessUsers();
      })

      .catch((err) => {
        setSwitchLoading(false);

        console.log(err);
      });
  };

  const UrlChangeSave = async () => {
    let payLoad = {
      path: changeUrlPath,
      team_uuid: params.uuid,
      script_uuid: params.slug,
    };
    if (changeUrlPath) {
      await axiosClient
        .put("/api/scripts/pathChangeRequest", payLoad)
        .then((res) => {
          getParticularScript();
          showToastSaveMessage(res.data.msg);
        })

        .catch((err) => {
          console.log(err);
        });
    } else {
      setError("required");
    }
  };

  //publish poppup

  const handleSendClick = async () => {
    let payload = {
      addEmail: emailState,
      script_uuid: params.slug,
    };
    console.log(emailState);
    setSendLoading(true);
    if (emailState) {
      await axiosClient
        .post(`/api/scripts/scriptAccessUser`, payload)
        .then((res) => {
          showToastMessage(res.data);
          setSendLoading(false);
          getAccessUsers();

          setEmaiState("");
        })
        .catch((err) => {
          showToastErrorMessage(err.response.data);
          setSendLoading(false);
        });
    } else {
      showToastErrorMessage("Required Field");
      setSendLoading(false);
    }
  };

  const validateEmail = (e) => {
    setEmaiState(e.target.value);
    // Regular expression for basic email format validation
    if (e.target.value == "") {
      return setError("Required Field");
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValidEmail = emailPattern.test(e.target.value);
    setError("Please Enter Vaild Email !");

    // Update email state if needed
    if (isValidEmail) {
      setEmaiState(e.target.value);
      setError(false);
    }
  };

  const getAccessUsers = async () => {
    try {
      await axiosClient
        .get(`/api/scripts/getScriptUserAccessEmail/${params.slug}`)
        .then((res) => {
          setAccessUsers(res.data.getUsers);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemove = async (uuid) => {
    if (uuid) {
      try {
        await axiosClient
          .delete(
            `/api/user/removeAccessUser?email=${uuid}&script_uuid=${params.slug}`
          )
          .then((res) => {
            showToastMessage(res.data.msg);
            getAccessUsers();
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleUserSearch = async (e) => {
    let value = e.target.value;
    await axiosClient
      .get(`/api/user/searchAccessUsers/${params.slug}?q=${value}`)
      .then((res) => {
        if (res.data.accessUsers.length > 0) {
          setAccessUsers(res.data.accessUsers);
          console.log(res.data.accessUsers, "search");
        } else {
          setAccessUsers(null);
        }
      })
      .catch((err) => {
        const response = err.response;
        if (response && response?.status === 404) {
          getAccessUsers();
        } else {
          console.error("Error:", response?.status);
        }
      });
  };

  //multipe select

  const handleCheckboxChange = (e, record) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedIds((prevIds) => [...prevIds, record.email]);
    } else {
      setSelectedIds((prevIds) =>
        prevIds.filter((email) => email !== record.email)
      );
    }
  };

  const handleMultipleUserDelete = async () => {
    await axiosClient
      .delete(`/api/user/selectedUsersRemoved?script_uuid=${params.slug}`, {
        data: selectedIds,
      })
      .then((res) => {
        showToastMessage(res.data.message);
        getAccessUsers();
        setSelectedIds([]);
        console.log(res);
      })
      .catch((err) => {
        const response = err.response;
        console.log(response);
      });
  };

  const handleSelectComment = () => {
    setCommentPopup(selectedText);
  };

  // const [handleComments, setHandleComments] = useState(null);
  // const [allSelectComments, setAllSelectComments] = useState([]);

  // const [handleEditValue, setHandleEditValue] = useState(null);

  // const handleParentComment = (uuid ,value) => {
  //   let payLoad = {
  //     id : divId,
  //     page_uuid: pageIds,
  //     content: selectedText ? selectedText : value,
  //     comments: handleComments,
  //     comment_uuid: uuid,
  //   };

  //   axiosClient
  //     .post(`/api/comments/postLineComments`, payLoad)
  //     .then((res) => {
  //       getSelectComments();
  //       setHandleComments(null);
  //     })

  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getSelectComments = () => {
    axiosClient
      .get(`/api/comments/getLineComments?page_uuid=${pageIds}`)
      .then((res) => {
        setAllSelectComments(res.data.hierarchy);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectedCommentDelete = (uuid) => {
    axiosClient
      .delete(`/api/comments/deleteLineComments?id=${uuid}`)
      .then((res) => {
        getSelectComments();
      })

      .catch((err) => {
        console.log(err);
      });
  };
  // handleSelectedCommentEdit
  const handleSelectedCommentResolve = (uuid) => {
    axiosClient
      .delete(`/api/comments/resolveLineComments?id=${uuid}`)
      .then((res) => {
        getSelectComments();
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const [handleComments, setHandleComments] = useState(null);

  const [selectCommentValue, setSelectCommentValue] = useState("");

  const [selectEditCommentValue, setSelectEditCommentValue] = useState("");

  const [allSelectComments, setAllSelectComments] = useState([]);

  const [handleEditValue, setHandleEditValue] = useState(null);

  const [selectedOption, setSelectedOption] = useState([]);

  const [selectCommentLoading, setSelectedCommentLoading] = useState(false);
  const [selectChildCommentLoading, setSelectedChildCommentLoading] =
    useState(false);

  const handleParentComment = () => {
    setSelectedCommentLoading(true);
    if (selectedOption && selectedOption.id) {
      const uuid = selectedOption.id;
      console.log("Selected UUID:", uuid);
    }

    // Prepare the data to send in the API call
    const data = {
      selectCommentValue,
      selectedOption,
    };

    console.log(data);
    let payLoad = {
      id: divId,
      page_uuid: pageIds,
      content: selectedText ? selectedText : null,
      comments: data,
      comment_uuid: null,
    };
    // console.log(handleComments);
    axiosClient
      .post(`/api/comments/postLineComments`, payLoad)
      .then((res) => {
        getSelectComments();
        setHandleComments(null);
        setSelectCommentValue("");
        setSelectedOption([]);
        // handleSave();
        setSelectedText(null);
        setSelectedCommentLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSelectedCommentLoading(false);
      });
  };

  const handleChildComment = (uuid, value) => {
    setSelectedChildCommentLoading(true);
    if (selectedOption && selectedOption.id) {
      const uuid = selectedOption.id;
      console.log("Selected UUID:", uuid);
    }

    // Prepare the data to send in the API call
    const data = {
      selectEditCommentValue,
      selectedOption,
    };

    console.log(data);
    let payLoad = {
      id: divId,
      page_uuid: pageIds,
      content: selectedText ? selectedText : value,
      comments: data,
      comment_uuid: uuid,
    };
    // console.log(handleComments);
    axiosClient
      .post(`/api/comments/postLineComments`, payLoad)
      .then((res) => {
        getSelectComments();
        setHandleComments(null);
        setSelectEditCommentValue("");
        setSelectedOption([]);
        // handleSave();
        setSelectedText(null);
        setSelectedChildCommentLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSelectedChildCommentLoading(false);
      });
  };

  return (
    <>
      <ViewHeader
        inputValue={inputValue}
        setInputValue={setInputValue}
        renderScript={renderScript}
        scriptError={scriptError}
        publish={publish}
        handleEdit={handleEdit}
        role={role}
        onChange={onChange}
        setPublishPopup={setPublishPopup}
      />

      <ViewPage
        treeNode={treeNode}
        contentPage={contentPage}
        pageContent={pageContent}
        particularTitle={particularTitle}
        setParticularTitle={setParticularTitle}
        description={description}
        setDescription={setDescription}
        handleScriptMouseEnter={handleScriptMouseEnter}
        handleScriptMouseLeave={handleScriptMouseLeave}
        publish={publish}
        editorValue={editorValue}
        setEditorValue={setEditorValue}
        editorContent={editorContent}
        setEditorContent={setEditorContent}
        renderScript={renderScript}
        parentOpen={parentOpen}
        teamUuid={teamUuid}
        emoji={emoji}
        revisiedHistory={revisiedHistory}
        handleSelectComment={handleSelectComment}
        commentIconPosition={commentIconPosition}
        selectedText={selectedText}
        setSelectedText={setSelectedText}
        commentPopup={commentPopup}
        setCommentPopup={setCommentPopup}
        handleComments={handleComments}
        setHandleComments={setHandleComments}
        handleParentComment={handleParentComment}
        allSelectComments={allSelectComments}
        handleSelectedCommentDelete={handleSelectedCommentDelete}
        handleSelectedCommentResolve={handleSelectedCommentResolve}
        setHandleEditValue={setHandleEditValue}
        getSelectComments={getSelectComments}
        userDetail={userDetail}
        setDivId={setDivId}
        divId={divId}
        activeTeamMembers={activeTeamMembers}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        selectCommentValue={selectCommentValue}
        setSelectCommentValue={setSelectCommentValue}
        selectEditCommentValue={selectEditCommentValue}
        setSelectEditCommentValue={setSelectEditCommentValue}
        handleChildComment={handleChildComment}
        selectChildCommentLoading={selectChildCommentLoading}
      />

      {loading && (
        <>
          <div className="bg-[#aeaeca] opacity-[0.5] w-[100%] h-[100vh] absolute top-0 left-0  z-10"></div>
          <div className="">
            <p className="absolute top-[48%] left-[48%] z-50 ">
              <HashLoader color="#3197e8" />
            </p>
          </div>
        </>
      )}

      {publishPopup && (
        <PublishPopup
          publishedData={publishedData}
          changeUrlPath={changeUrlPath}
          setChangeUrlPath={setChangeUrlPath}
          UrlChangeSave={UrlChangeSave}
          error={error}
          setError={setError}
          onChange={onChange}
          switchLoading={switchLoading}
          onAccessChanges={onAccessChanges}
          validateEmail={validateEmail}
          handleSendClick={handleSendClick}
          emailState={emailState}
          setEmaiState={setEmaiState}
          manageState={manageState}
          setManage={setManage}
          accessUsers={accessUsers}
          setAccessUsers={setAccessUsers}
          sendLoading={sendLoading}
          handleRemove={handleRemove}
          handleUserSearch={handleUserSearch}
          handleCheckboxChange={handleCheckboxChange}
          selectedIds={selectedIds}
          handleMultipleUserDelete={handleMultipleUserDelete}
        />
      )}
    </>
  );
};
