import React, { useEffect, useState } from "react";
import { ScriptComponents } from "../components/ScriptComponents";
import { useMyContext } from "../../context/AppContext";
import { ScriptEditor } from "../components/ScriptEditor";
import axiosClient from "../../axios-client";
import { useNavigate, useParams } from "react-router-dom";

export const Scripts = () => {
  const { role, hasChanges, setHasChanges } = useMyContext();

 
  const params = useParams();
  const navigate = useNavigate();
  const [published, setPublished] = useState(false);

  useEffect(() => {
    
    const handleBeforeUnload = (event) => {

      if (hasChanges) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    // window.onbeforeunload = () => handleBeforeUnload;
    // return () => {
    //   window.onbeforeunload = handleBeforeUnload;
    // };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);


  useEffect(() => {
    getParticularScript();
  }, [params.slug]);

  const getParticularScript = async () => {
    // setLoading(true);
    let script_uuid = params.slug;
    await axiosClient
      .get(`/api/dashboard/getScriptAndPage/${script_uuid}`)
      .then((res) => {
        if (res.status == 200) {
          if(role == 2){
            navigate(`/dashboard/${params.uuid}/edit/${params.slug}`);
            return;
          }
          if (
            res.data.getScriptAndPages.is_published == 1 &&
            res.data.getScriptAndPages.status == "merge-request"
          ) {
            navigate(`/dashboard/${params.uuid}/changes/${params.slug}`);
          } else if (
            res.data.getScriptAndPages.is_published == 1 &&
            res.data.getScriptAndPages.status == "edit-request"
          ) {
            navigate(`/dashboard/${params.uuid}/edit/${params.slug}`);
          } else if (
            res.data.getScriptAndPages.is_published == 0 &&
            res.data.getScriptAndPages.status == null
          ) {
            setPublished(true);
          } else {
            setPublished(true);
          }
        }

        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };
  return (
    <>
      {published && <ScriptComponents />}
    </>
  );
};
