import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import List from "@editorjs/list";
import Table from "@editorjs/table";
import Underline from "@editorjs/underline";
import Marker from "@editorjs/marker";
import Quote from "@editorjs/quote";
import Embed from "@editorjs/embed";
import ImageTool from "@editorjs/image";
import BreakLine from "editorjs-break-line";
import axiosClient from "../../axios-client";
import AttachesTool from "@editorjs/attaches";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import { useMyContext } from "../../context/AppContext";
import { useParams } from "react-router-dom";

export const EditorComponents = (props) => {
  const params = useParams();
  const {
    screenHeight,
    setScreenHeight,
    hasChanges,
    setHasChanges,
    handleLinkClick,
    teamName,
    setTeam,
  } = useMyContext();

  const ejInstance = useRef();
  // Code Corrected

  class CustomTool {
    constructor({ data, api }) {
      this.api = api;
      this.data = data;
      this.container = null;
      this.suggestionsEndpoint = `/api/teams/getAllPublishUrlForTeam/${params.uuid}`;
    }

    static get toolbox() {
      return {
        icon: '<i class="fa-solid fa-link"></i>',
        title: "PublishedURLAttachment",
      };
    }

    async fetchSuggestions(inputValue) {
      try {
        const response = await axiosClient.get(`${this.suggestionsEndpoint}`);
        return response.data.publishScripts || [];
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        return [];
      }
    }

    async renderSuggestions(input, suggestionsDropdown) {
      const inputValue = input.value.toLowerCase().trim();

      // Add a condition to handle empty input
      if (inputValue === "") {
        suggestionsDropdown.style.display = "none";
        return;
      }

      const suggestions = await this.fetchSuggestions(inputValue);

      suggestionsDropdown.innerHTML = "";

      // Add the heading
      const heading = document.createElement("div");
      heading.className = "suggestions-heading";
      heading.style.fontWeight = "bold";
      heading.innerText = teamName
        ? `${teamName} Team's Search URLs here`
        : "Search URL here";

      suggestionsDropdown.appendChild(heading);

      const filteredSuggestions = suggestions.filter((suggestion) =>
        suggestion.title.toLowerCase().includes(inputValue)
      );

      filteredSuggestions.forEach((suggestion, index) => {
        const suggestionItem = document.createElement("div");
        suggestionItem.setAttribute("class", "suggestions");
        const indexDisplay = index + 1;

        const link = document.createElement("a");
        link.setAttribute("class", "item");
        link.href = suggestion.path;
        link.textContent = suggestion.title;

        suggestionItem.innerHTML = `${indexDisplay}. <a href="${suggestion.path}">${suggestion.title}</a>`;

        suggestionItem.style.cursor = "pointer";

        suggestionItem.addEventListener("click", (event) => {
          event.preventDefault(); // Prevents the default behavior (e.g., navigating to the link)
          input.value = suggestion.title;
          input.id = suggestion.path;
          suggestionsDropdown.style.display = "none";
        });

        suggestionsDropdown.appendChild(suggestionItem);
      });

      suggestionsDropdown.style.display =
        filteredSuggestions.length > 0 ? "block" : "none";
    }

    render() {
      this.container = document.createElement("div");

      const input = document.createElement("input");
      input.placeholder = "Search URL or Enter a link...";
      input.value = this.data.link || "";
      input.style.border = "none";
      input.style.outline = "none";
      input.style.width = "100%";
      input.style.background = "transparent";
      input.style.color = "blue";

      const suggestionsDropdown = document.createElement("div");
      suggestionsDropdown.className = "suggestions-dropdown";
      suggestionsDropdown.style.display = "none";

      input.addEventListener("input", () => {
        this.renderSuggestions(input, suggestionsDropdown);
      });

      this.container.appendChild(input);
      this.container.appendChild(suggestionsDropdown);

      return this.container;
    }

    save() {
      return {
        link: this.container.querySelector("input").value,
        links: this.container.querySelector("input").id,
      };
    }
  }

  class Comment {
    static get isInline() {
      return true;
    }

    constructor({ api, config, readOnly }) {
      this.api = api;
      this.button = null;
      this.state = false;
      this.config = config;
      this.readOnly = readOnly;
    }

    render() {
      this.button = document.createElement("button");
      this.button.type = "button";

      // Add comment icon (you can replace this with your actual comment icon)
      const commentIcon = document.createElement("span");
      // commentIcon.innerHTML = "&#128172;"; // Unicode for a comment icon
      commentIcon.innerHTML = `<i class="fa-regular fa-comment text-[#1F72FD]"></i>`

      // Add onclick event to the comment icon
      commentIcon.addEventListener("click", () => this.onCommentIconClick());

      this.button.appendChild(commentIcon);

      // Set additional styling for the button if needed
      this.button.style.backgroundColor = "transparent";
      this.button.style.border = "none";
      this.button.style.cursor = "pointer";

      return this.button;
    }

    onCommentIconClick() {
      const selection = window.getSelection();
      const selectedTextContent = selection.toString().trim();

      if (selectedTextContent) {
        const range = selection.getRangeAt(0); // Get the range from the selection

        // Create a temporary element to contain the selected content
        const tempContainer = document.createElement("div");
        tempContainer.appendChild(range.cloneContents());

        // Create MARK element
        const mark = document.createElement("span");

        // Generate a sanitized unique ID
        const uniqueId = this.generateSanitizedUniqueId();

        // Set the ID to the span
        mark.id = uniqueId;

        mark.appendChild(tempContainer.firstChild);

        // Insert the new element
        range.deleteContents();
        range.insertNode(mark);

        props.setSelectedText(mark.innerText);
        props.setDivId(mark.id);

        props.handleSelectComment(true);
      }
    }

    surround(range) {
      if (this.state) {
        // If the highlight is already applied, do nothing for now
        return;
      }

      // Update state or perform any other necessary actions
      this.state = true;
    }

    checkState(selection) {
      const text = selection.anchorNode;

      if (!text) {
        return;
      }

      const anchorElement = text instanceof Element ? text : text.parentElement;

      this.state = !!anchorElement.closest("span");
    }

    generateSanitizedUniqueId() {
      const baseId = `custom-inline-tool-${Date.now()}`;

      return baseId;
    }
    static get sanitize() {
      return {
        span: {
          id: true,
        },
      };
    }
  }

  const initEditor = () => {
    const editor = new EditorJS({
      holder: "editorjs",
      onReady: () => {
        ejInstance.current = editor;
      },
      autofocus: true,
      readOnly: false,
      data: props.editorValue,

      onChange: async () => {
        try {
          // Check if editor is defined before calling save method
          if (editor) {
            const content = await editor.saver.save();
            const contentWithUniqueId = {
              ...content,
              blocks: content.blocks.map((block) => {
                if (block.type === "header") {
                  block.data.text = `<h${block.data.level} id="${block.id}">${block.data.text}</h${block.data.level}>`;
                }
                return block;
              }),
            };
            setHasChanges(true);

            props.setEditorContent(contentWithUniqueId);
          }
        } catch (error) {
          console.error("Error saving content:", error);
        }
      },
      tools: {
        paragraph: {
          class: Paragraph,
          config: {
            placeholder: "Type something here...",
          },
        },

        Comment: Comment,

        header: Header,
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },
        customTool: CustomTool,
        Marker: {
          class: Marker,
          shortcut: "CMD+SHIFT+M",
        },
        image: {
          class: ImageTool,
          config: {
            uploader: {
              async uploadByFile(file) {
                const formData = new FormData();

                formData.append("image", file);

                const response = await axiosClient.post(
                  `/api/dashboard/uploadImage`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                    withCredentials: false,
                  }
                );
                if (response.data.success === true) {
                  return Promise.resolve({
                    success: 1,
                    file: {
                      url: response?.data?.image?.filename,
                    },
                  });
                }
              },

              async uploadByUrl(url) {
                const response = await axiosClient.post(
                  `/api/uploadImage/createByUrl`,
                  {
                    url,
                  }
                );

                if (response.data.success === 1) {
                  return response.data;
                }
              },
            },

            inlineToolbar: true,
          },
        },
        attaches: {
          class: AttachesTool,
          config: {
            uploader: {
              async uploadByFile(file) {
                const formData = new FormData();

                formData.append("image", file);

                const response = await axiosClient.post(
                  `/api/dashboard/uploadImage`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                    withCredentials: false,
                  }
                );
                if (response.data.success === true) {
                  return Promise.resolve({
                    success: 1,
                    file: {
                      url: response?.data?.image?.filename,
                    },
                  });
                }
              },
            },
          },
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+O",
          config: {
            quotePlaceholder: "Enter a quote",
            captionPlaceholder: "Quote's author",
          },
        },
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true,
              coub: true,
              facebook: true,
              instagram: true,
            },
          },
        },
        breakLine: {
          class: BreakLine,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+ENTER",
        },

        underline: Underline,
      },
      placeholder: "Type here something",
    });
  };

  // This will run only once
  useEffect(() => {
    if (ejInstance.current === null && !ejInstance.current) {
      initEditor();
    }

    return async () => {
      ejInstance?.current?.destroy();

      ejInstance.current = null;
    };
  }, [props.editorValue]);

  return (
    // <>
    <div id="editorjs" className="z-0 2xl:mr-1240px font-inter"></div>

    /* {props.selectedText && props.commentIconPosition && ( */
    // <div
    //   style={{
    //     position: "absolute",
    //     left: `${props.commentIconPosition.x}px`,
    //     top: `${props.commentIconPosition.y}px`,
    //     zIndex: 9999, // Ensure the icon is above other elements
    //     pointerEvents: "auto", // Ensure the icon is clickable
    //   }}
    //   className="cursor-pointer"
    //   onClick={props.handleSelectComment}
    // >
    //   {/* Add a console.log here to ensure the function is being called */}
    //   <i className="fa-regular fa-message text-lg text-gray-800 cursor-pointer"></i>
    // </div>
    // )}
    // </>
  );
};
