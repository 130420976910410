import React from "react";

export const BatchHeader = (props) => {
  // const { openSideNave, setOpenSideNave } = useMyContext();

  // const handleSideBar = () => {
  //   setOpenSideNave("block");

  // };
  return (
    <div className="bg-[#ffff]  w-[100%]">
      <div
        className={`flex items-center  m-auto justify-between  relative w-[100%]  2xl:py-[30px] pt-[15.5px] pb-[15.5px] pl-[30px] pr-[30px] shadow-sm`}
      >
         {/* <div
            className="2xl:hidden xl:hidden lg:hidden  phone:block phone:text-[12px] "
            onClick={handleSideBar}
          >
            <i className="fa-solid fa-bars"></i>
          </div> */}
        <div className="text-[22px] phone:text-[18px]  font-medium text-textPrimary">
          {props.batchTitle}
        </div>
      </div>
    </div>
  );
};
