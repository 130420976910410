
import React from "react";


function App() {
  return (
    <div className='flex'>
      <h1>Hello</h1>
    </div>
  );
}

export default App;
