import React from 'react'
import SignupComponents from '../signupComponents/SignupComponents'

export default function SignupContainer() {

  return (

    <SignupComponents />
    
  ) 
}
