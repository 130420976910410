import React, { useState } from "react";
import { Button, Modal } from "antd";

const ProfilePagePopup = ({
  profilePopup,
  setProfilePopup,
  scriptUserInfo,

  handleUserDetail,
  username,
  setUsername,
  setImage,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(profilePopup);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setProfilePopup(false);
    handleRemoveImage();
  };

  const [selected, setSelected] = useState(scriptUserInfo?.avatar);
  const [isHovered, setIsHovered] = useState(false);

  // const [image, setImage] = useState(null);

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  const [selectedImage, setSelectedImage] = useState();

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // You can perform additional checks here if needed
      setSelectedImage(URL.createObjectURL(file));
      setImage(file);
    }
  };


  return (
    <>
      <Modal
        title="Profile Setting"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="py-20"
      >
        <div className="">
          <div className="flex items-center space-x-5">
            <div
              className={`w-32 h-32 ${!selectedImage && "border-dotted"} ${
                !selected && "border-2"
              } border-gray-400 relative overflow-hidden`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {selectedImage ? (
                <>
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="object-cover w-full h-full"
                  />
                  {isHovered && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                      <label htmlFor="imageInput" className="cursor-pointer">
                        <span className="text-white">Upload</span>
                        <input
                          type="file"
                          id="imageInput"
                          accept="image/*"
                          className="hidden"
                          onChange={handleImageChange}
                          // value={selected}
                        />
                      </label>
                    </div>
                  )}
                  <button
                    className="absolute top-1 right-1  bg-white text-gray-600 hover:text-red-500 hover:bg-red-100 pl-[8px] pr-[8px] text-[16px] pt-[2px] pb-[2px] rounded-[50%]"
                    onClick={handleRemoveImage}
                  >
                    X
                  </button>
                </>
              ) : (
                <>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <label htmlFor="imageInput" className="cursor-pointer">
                      <span className="text-gray-500">
                        <img
                          src={selected}
                          alt="Selected"
                          className="object-cover w-full h-full"
                        />
                      </span>
                      <input
                        type="file"
                        id="imageInput"
                        accept="image/*"
                        className="hidden"
                        onChange={handleImageChange}
                      />
                    </label>
                  </div>
                </>
              )}
            </div>

            <div className="w-[75%]">
              <div className="mt-3">
                <label className="">Username</label>
                <div>
                  <input
                    type="text"
                    value={username || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-[90%] pl-2 p-2.5 focus:outline-primary mt-2"
                    placeholder="UserName"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-3">
                <label className="">Email</label>
                <div>
                  <input
                    type="email"
                    value={(scriptUserInfo && scriptUserInfo?.email) || ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded  block w-[90%] pl-2 p-2.5 focus:outline-none mt-2"
                    placeholder="Email"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginTop: "20px",
            marginRight: "36px",
          }}
        >
          <button
            onClick={handleUserDetail}
            className="px-10 bg-primary text-white text-lg py-2 rounded-lg"
          >
            Submit
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ProfilePagePopup;
